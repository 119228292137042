import { SVGProps } from "react"


const SideMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={38}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#003648"
        d="M4.75 26.917c0 .874.709 1.583 1.583 1.583h25.334a1.583 1.583 0 0 0 0-3.167H6.333c-.874 0-1.583.71-1.583 1.584Zm0-7.917c0 .875.709 1.583 1.583 1.583h25.334a1.583 1.583 0 1 0 0-3.166H6.333c-.874 0-1.583.708-1.583 1.583Zm1.583-9.5a1.583 1.583 0 0 0 0 3.167h25.334a1.583 1.583 0 0 0 0-3.167H6.333Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h38v38H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SideMenu
