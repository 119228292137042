import { SVGProps } from 'react';

const FinancialIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props}>
    <circle cx={15} cy={15} r={15} fill="#CDD2D7" />
    <path
      fill="#637381"
      d="M14.932 9.279a5.807 5.807 0 1 0 5.807 5.807 5.813 5.813 0 0 0-5.807-5.807Zm1.888 3.663h-1.888a.663.663 0 0 0 0 1.327h.019a1.706 1.706 0 0 1 1.704 1.704v.166a1.706 1.706 0 0 1-1.202 1.628v1.04h-1.041v-.962H13.11v-1.042h1.838a.664.664 0 0 0 .663-.663v-.167a.664.664 0 0 0-.663-.662h-.018a1.705 1.705 0 0 1-.52-3.328v-.971h1.04v.89h1.367l.003 1.04Z"
    />
    <path
      fill="#637381"
      d="M14.932 6.698a8.388 8.388 0 1 0-.006 16.775 8.388 8.388 0 0 0 .006-16.775Zm0 15.235a6.85 6.85 0 1 1 6.845-6.847 6.857 6.857 0 0 1-6.845 6.847Z"
    />
    <path
      fill="#637381"
      d="M24.639 24.645a13.72 13.72 0 0 0 2.984-14.564C24.883 3.04 16.923-.46 9.881 2.28l.425 1.092c6.443-2.507 13.72.694 16.226 7.134a12.547 12.547 0 0 1-2.734 13.322l-.833-.812a.62.62 0 0 0-1.051.452l.031 2.206a.62.62 0 0 0 .626.61l2.206-.026a.619.619 0 0 0 .424-1.063l-.562-.55ZM10.17 26.391A12.525 12.525 0 0 1 6.267 6.15l.738.715a.619.619 0 0 0 1.05-.451l-.03-2.206a.617.617 0 0 0-.627-.61l-2.205.026a.619.619 0 0 0-.427 1.066l.66.643a13.702 13.702 0 0 0 14.758 22.364l-.424-1.091a12.449 12.449 0 0 1-9.588-.215Z"
    />
  </svg>
);
export default FinancialIcon;
