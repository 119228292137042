import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'src/auth/useAuthContext';
import FormProvider from 'src/components/hook-form/FormProvider';
import { LoginFormsProps } from 'src/@types/auth/login';
import PhoneCountryCode from 'src/components/hook-form/PhoneCountryCode';
import LoginSchema from 'src/utils/forms/validationSchema/Login';
import ImpersonateAlert from 'src/sections/shared/ImpersonateAlert';
import { PATH_AUTH } from 'src/routes/paths';

export default function AuthLoginForm({ translate }: LoginFormsProps) {
  const queryParameters = new URLSearchParams(window.location.search);
  const impersonate = queryParameters.get("impersonate");
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const { search } = useLocation();

  const defaultValues = {
    PhoneNumber: undefined,
  };

  const methods = useForm<LoginFormsProps>({
    resolver: yupResolver(LoginSchema(translate)),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: LoginFormsProps) => {
    try {
      if (login) {
        const response = await login({
          ...data,
          PhoneNumber: `${data?.PhoneNumber?.toString().replace('+', '00').replace(/\s/g, '')}`,
          isLogin: true,
          impersonate: !!impersonate
        });
        const { success: isSuccess } = response.data;
       
        if (isSuccess) {
          navigate(`${PATH_AUTH.otp}${search}`);
        }
      }
    } catch (error) {
      setError('PhoneNumber', {
        type: 'server',
        message: error.message,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {impersonate && (
        <ImpersonateAlert />
      )}
      <Stack
        spacing={3}
        sx={{
          mt: 2,
          mb: 4,
          width:'100%'
        }}
      >
        <PhoneCountryCode name="PhoneNumber" autoFocus fullWidth />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
      >
        {`${translate('auth.sign_in')}`}
      </LoadingButton>
    </FormProvider>
  );
}
