import { useFormContext, Controller } from 'react-hook-form';
import { Box, TextFieldProps } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { useTheme } from '@mui/material/styles';

import { useLocales } from 'src/locales';

type Props = TextFieldProps & {
  name: string;
};

export default function PhoneCountryCode({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();
  const { translate } = useLocales();
  const themeObj = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // @ts-ignore: Unreachable props error
        <MuiTelInput
          {...field}
          fullWidth
          dir="ltr"
          disableFormatting
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          sx={{
            direction: `${themeObj.direction === 'rtl' ? 'rtl' : 'ltr'}`,
            "& .MuiTelInput-Flag > picture > img": {
              borderRadius: '50%',
              overflow: 'hidden',
              width: '24px', height: '24px',
            },
            '& .MuiInputAdornment-root > .MuiTypography-root': {
              color: '#305530'
            },
            '& .MuiTypography-body1': {
              borderLeft: `${themeObj.direction === 'rtl' ? '1px solid #e0e0e0' : 'none'}`,
              borderRight: `${themeObj.direction === 'rtl' ? 'none' : '1px solid #e0e0e0'}`,
              paddingRight: `${themeObj.direction === 'rtl' ? 0 : '10px'}`,
              paddingLeft: `${themeObj.direction === 'rtl' ? '10px' : 0}`,
            }
          }}
          label={`${translate('form.mobile_number')}`}
          defaultCountry="SA"
          placeholder="5xxxxxxxx"
          disableDropdown
          forceCallingCode
          {...other}
        />
      )}
    />
  );
}
