import { Popover, PopoverOrigin } from '@mui/material';
import { useLocales } from 'src/locales';
import getPosition from './getPosition';
import { StyledArrow } from './styles';
import { MenuPopoverProps } from './types';

export default function MenuPopover({
  open,
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}: MenuPopoverProps) {
  const { style } = getPosition(arrow);
  const { currentLang } = useLocales();

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      PaperProps={{
        sx: {
          p: 1,
          width: 'auto',
          overflow: 'inherit',
          ...style,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
          },
          ...sx,
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: currentLang.value === 'ar' ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: currentLang.value === 'ar' ? 'left' : 'right',
      }}

      {...other}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}

      {children}
    </Popover>
  );
}
