// @mui
import { useContext, useState } from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import GuestPopup from 'src/pages/auth/login/GuestLogin';
import { useLocales } from '../../../locales';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function GuestNavAccount() {
  const theme = useTheme();
  const { translate } = useLocales();
  const [openGuestPopup, setOpenGuestPopup] = useState(false);

  return (
    <StyledRoot sx={{width:'248px',}}>     
     <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography variant="subtitle2" noWrap>
         {translate('Welcome_to_LAWAZEM')}
        </Typography>
        <Typography variant="body1" sx={{fontSize:'14px' }}>
          <Link sx={{ typography: 'button', fontWeight:600, cursor:'pointer' }} underline="always" onClick={()=>setOpenGuestPopup(true)}>
            {translate('sign_in')}
          </Link>  &nbsp;
          {translate('to_get_started')}
          
        </Typography>
        {openGuestPopup &&
          <GuestPopup open={openGuestPopup} onClose={() => setOpenGuestPopup(false)} />
         }
      </Box>
    </StyledRoot>
  );
}
