import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Stack, Drawer } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import { NAV } from '../../../config-global';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import GuestNavSectionVertical from '../../../components/nav-section/vertical/Guest';
import navConfig from './Guestconfig-navigation';
import GuestNavAccount from './GuestNavAccount';
import mobileConfig from './mobile-navigation';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function GuestNavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 5,
          pb: 2,
          px: 2,
          flexShrink: 0,
        }}
      >
        <Logo redirectUrl="/" sx={{ mb: '10px' }} />
        <GuestNavAccount />
      </Stack>

      <GuestNavSectionVertical data={isDesktop ? navConfig : mobileConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
        zIndex: 1125,
        backgroundColor: 'white',
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
              left: 'auto',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              maxHeight: '100% !important',
              width: NAV.W_DASHBOARD,
              borderRadius: '0px !important',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
