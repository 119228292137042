import useResponsive from 'src/hooks/useResponsive';
import { Typography, Stack, StackProps } from '@mui/material';
import Image from '../image';

interface EmptyContentProps extends StackProps {
  title: any;
  img?: string;
  description?: string;
}

export default function EmptyContent({ title, description, img, sx, ...other }: EmptyContentProps) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        textAlign: 'center',
        p: (theme) => theme.spacing(isDesktop? 8:30, 2),
        ...sx,
      }}
      {...other}
    >
      <Image
        disabledEffect
        alt="empty content"
        src={img || '/assets/illustrations/illustration_empty_content.svg'}
        sx={{ height: isDesktop ? "120px" : "130px", width:isDesktop? "150px":"117.355px", mb: 3 }}
      />

      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 700,
          ...(!description && { color: '#637381', textAlign: 'center', width: '80%' }),
        }}
      >
        {title}
      </Typography>

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '12px' }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
