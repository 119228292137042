import { ProductFruits as Fruits } from 'react-product-fruits';
import { useLocales } from 'src/locales';

const ProductFruits = ({ userInfo }: any) => {
  const { currentLang } = useLocales();

  return (
    <Fruits
      workspaceCode="x7RAgGJhe1iOO93a"
      language={currentLang.value}
      user={{
        username: userInfo?.firstName,
        email: userInfo?.email,
        firstname: userInfo?.firstName,
        lastname: userInfo?.lastName,
      }}
    />
  );
};

export default ProductFruits;
