const ar = {
  lawazem: 'لوازم',
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  Add_new_company: 'إضافة شركة جديدة',
  Add_company: 'إضافة الشركة',
  Help: 'المساعدة',
  UserProfile: ' إدارة حساب المستخدم',
  Account: 'الحساب',
  Reports: 'التقارير',
  ReorderingLists: 'قوائم التسوق',
  related_invoices: 'الفواتير ذات الصلة',
  management: 'الإدارة',
  UserDetails: 'تفاصيل الحساب',
  Firstname: 'الاسم الأول',
  Lastname: 'الأسم الاخير',
  job_title: 'الوظيفة',
  invoices: 'الفواتير',
  Download_PDF: 'تحميل الملف',
  Invoices: 'الفواتير',
  RequestForQuotation: 'طلب تسعير',
  rfq: 'طلب تسعير',
  submit: 'أرسل',
  upload: 'رفع',
  sale_order: 'طلب شراء',
  RFQs: 'طلب ',
  Order: 'طلب',
  By_signing_up_i_agree_to: 'بتسجيل حساب جديد، أوافق على',
  Terms_of_Use_and_Privacy_Policy: 'شروط الأستخدام وسياسة الخصوصية.',
  Orders: 'الطلبات',
  RFQ_Content: 'محتوي طلب التسعير',
  Attachment: 'الملفات',
  Categories: 'الفئات',
  Marketplace: 'المتجر',
  home: 'الرئيسية',
  CustomerService: 'خدمة العملاء',
  Go_to_marketplace: 'الرجوع إلى المتجر',
  access_denied: 'غير مسموح بالدخول',
  You_do_not_have_permission_to_view_this_page: 'ليس لديك الإذن لعرض هذه الصفحة',
  CompanyProfile: 'إدارة حساب الشركة',
  CompaniesList: 'قائمة الشركات',
  switch_company: 'تغيير الشركة',
  CompanyDetails: 'تفاصيل الشركة',
  companyname: 'أسم الشركة',
  VatID: 'رقم البطاقة الضريبية',
  Commercialregistrationnumber: 'رقم السجل التجارى',
  Catering: 'تقديم الطعام وتنظيف المستهلكات',
  no_rfq_yet: ' لا يوجد طلبات حاليا',
  rfq_no_result: 'لا يوجد نتائج',
  OfficeFurniture: 'اثاث',
  app: `تطبيق`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  privacy_policy: 'سياسة الخصوصية لدينا',
  terms_service: 'شروط الخدمة',
  signup_agree_to: 'بالتسجيل ، أنا أوافق على',
  and: 'و',
  auth: {
    slogan: 'اطلب كل المنتجات بكل سهولة وبأفضل سعر',
    sign_in_to_lawazem: 'تسجيل الدخول في لوازم',
    sign_in: 'تسجيل الدخول',
    login: 'تسجيل الدخول',
    new_user: 'مستخدم جديد؟',
  },
  otp: {
    mobile_verification: 'التحقق من رقم الهاتف',
    otp_message: 'الرجاء إدخال الرمز المرسل إلى رقم هاتفك المحمول',
    verify: 'تأكيد',
    dont_have_code: 'لم يصلك رمز التحقق؟',
    resend_code: 'إعادة إرسال',
    return_to_sign_in: 'العودة الي تسجيل الدخول',
    return_to_sign_Up: ' العودة الي التسجيل',
    resend_otp_success_message: 'تم إرسال رمز التحقق بنجاح',
    message: {
      please_enter_valid_OTP_number: 'يرجى إدخال رمز التحقق الصحيح',
      account_is_created_successfully: 'تم تسجيل الحساب بنجاح',
      login_successful: 'تم تسجيل الدخول بنجاح',
    },
  },
  signup: {
    start_free: 'ابدأ مجانًا تمامًا.',
    have_account: 'هل لديك حساب؟',
    create_account: 'انشاء حساب',
  },
  form: {
    first_name: 'الأسم الأول',
    last_name: 'الاسم الاخير',
    job_title: 'الوظيفة',
    email_address: 'البريد الإلكترونى',
    mobile_number: 'رقم الجوال',
    company_name: 'اسم الشركة',
    vat_id: 'رقم السجل الضريبى',
    commercial_registration: 'رقم السجل التجارى',
    optional: 'اختيارى',
    name: 'الأسم',
    description: 'الوصف',
    save: 'حفظ',
    cancel: 'الغاء',
    name_validation_unique: 'يجب أن يكون الاسم غير مكرر',
  },
  form_validation: {
    mandatory: 'مطلوب',
    address_mandatory: 'العنوان مطلوب',
    valid_email_format: 'يرجى إدخال بريد الكترونى صحيح',
    between_03_50_format: 'يجب أن يتكون من 3 الى 50 حرف',
    max_50_format: 'الحد الاقصى 50 حرف',
    max_100_format: 'الحد الاقصى 100 حرف',
    max_200_format: 'الحد الاقصى 200 حرف',
    max_500_format: 'الحد الاقصى 500 حرف',
    valid_saudi_mobile: 'يرجى إدخال رقم جوال سعودي صحيح',
    accept_15_characters: 'يقبل بحد أقصى 15 رقماً',
    email_already_exists: 'البريد الالكترونى مسجل بالفعل من قبل',
    phone_already_exists: ' رقم الهاتف مسجل بالفعل من قبل',
    the_phone_number_should_contain_9_numbers: 'يجب أن يحتوي رقم الجوال على 9 أرقام',
    please_enter_numbers_only: 'يرجى إدخال أرقام فقط',
    address_within_saudi_arabia: 'يرجى اختيار عنوان التوصيل داخل السعودية',
    payment_option_required: 'من فضلك اختر وسيلة للدفع',
    allowed_files_types: 'الملفات المسموح بها هي صورة او ملف PDF أو ملف word',
    allowed_files_size: 'حجم الملف المسموح به هو 500 KB',
    allowed_pdf_only: 'مسموح برفع ملف من نوع PDF',
    allowed_files_size_10: 'مسموح برفع ملف بحد اقصى حجم 10 ميجا بايت',
    rfq_submit_validation: 'لإكمال الطلب، يجب عليك إضافة وصف لمحتوى طلب أو إضافة منتج',
    end_date_later_today: 'يجب أن يكون تاريخ النهاية مساويًا لتاريخ اليوم أو بعده',

    price_validation: 'تم تعدي الحد الاقصى',
    amount_min_0: 'قيمة السعر يجب ان تكون اكبر من 0',
  },
  sar: 'ر.س',
  product_filter_name: 'الاسم',
  product_filter_new: 'الأحدث أولاً',
  product_filter_old: 'الأقدم أولاً',
  product_filter_price_high: 'السعر (أعلى سعر أولاً)',
  product_filter_price_low: 'السعر (أقل سعر أولاً)',
  product_arrange: 'ترتيب حسب',
  Select_your_company: 'أختر الشركة',
  product_not_available: 'لا توجد منتجات',
  price_min: 'السعر الأدنى',
  price_max: 'السعر الأقصى',
  min_price_amount_validation: 'السعر يجب ان يكون اكبر من السعر الأدنى',
  max_price_amount_validation: 'السعر يجب ان يكون أقل من السعر الأقصى',
  filters: 'التصنيف',
  price: 'السعر',
  clear: 'مسح الكل',
  clear_all: 'مسح الكل',
  select: 'أختر',
  logout: 'تسجيل خروج',
  logout_message: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
  cancel: 'الغاء',
  products_general_search_placeholder: 'أبحث عن المنتجات',
  products_search_placeholder: 'البحث خلال النتائج...',
  products_no_result: 'لا توجد منتجات تطابق المعايير التي اخترتها.',
  search: 'البحث',
  search_results_for: 'نتائج البحث عن ',
  filter_submit: 'تفعيل',
  VAT: 'ضريبة القيمة المضافة',
  quantity: 'الكمية',
  description: 'وصف المنتج',
  add_to_cart: 'أضف إلى السلة',
  add_to_list: 'أضف إلى قائمة',
  new_list: 'قائمة جديدة',
  create_new_list: 'إضافة قائمة جديدة',
  uptate_reorder_list: 'تعديل القائمة',
  created_successfully: 'تم الانشاء بنجاح',
  updated_successfully: 'تم التعديل بنجاح',
  please_select_quantity_before_adding: 'الرجاء تحديد الكمية قبل الإضافة إلى سلة التسوق',
  reorder_list_column: 'القائمة',
  number_of_products: 'عدد المنتجات',
  reorder: 'إعادة الطلب',
  view: 'مشاهدة',
  delete: 'حذف',
  delete_list: 'حذف القائمة',
  duplicate_list: 'نسخ القائمة',
  edit_list: 'تعديل القائمة',
  created_at: 'تاريخ الانشاء',
  no_lists_to_display: 'لا يوجد قوائم لعرضها',
  added_successfully: 'تمت الإضافة بنجاح',
  product_not_available_at_category: 'لا يوجد منتج متاح في هذه الفئة',
  add_new_list: 'إضافة قائمة جديدة',
  deleted_successfully: 'تم الحذف بنجاح',
  delete_list_confirm_message: 'هل أنت متأكد أنك تريد حذف هذه القائمة؟',
  delete_product_confirm_message: 'هل أنت متأكد أنك تريد حذف هذا المنتج؟',
  delete_all_cart_confirm_message: 'هل أنت متأكد أنك تريد حذف جميع المنتجات من سلة التسوق؟',
  no: 'لا',
  product: 'المنتج',
  fixed_list_tooltip_msg: 'لا يمكنك تعديل تلك القائمة التي تم إنشاؤها بواسطة لوازم',
  copy_of: 'نسخة من',
  cart: 'سلة التسوق',
  checkout: 'سلة التسوق',
  delivery_address: 'عنوان التوصيل',
  payment: 'الدفع',
  cart_is_empty: 'سلة التسوق فارغة',
  empty_cart_description: 'لم تقم بإضافة أي منتج بعد .. أستمر في التسوق',
  empty_rfq_table_description: 'لم تقم بإضافة أي منتج بعد .. قم بالبحث لاضافة منتاجاتك  ',
  continue_shopping: 'متابعة التسوق',
  order_summary: 'ملخص الطلب',
  subtotal: 'المجموع الفرعى',
  subtotal_vat: 'الضريبه',
  total: 'المجموع',
  total_price_mobile: 'المجموع',
  total_price: 'اجمالى السعر',
  checkout_btn_text: 'الدفع',
  vat_included: 'اجمالى الضريبة',
  list_doesnt_contain_product: 'القائمة لا تحتوى علي منتجات',
  selected: 'مختارة',
  duplicate_list_title: 'نسخ القائمة',
  product_item: 'منتج',
  clear_cart: 'حذف جميع المنتجات',
  delete_product_title: 'حذف المنتج',
  delete_cart_title: 'حذف المنتجات',
  pdf_quotation: 'اقتباس',
  cart_terms_msg: 'بإستكمال الطلب , أوافق على منح شركة لوازم أمرًا بالتنفيذ وتسليم الطلب',
  updated_price_message: 'تم تحديث الأسعار! يُرجى مراجعة الأسعار المحدثة أدناه قبل  إستكمال طلبك',
  deliver_to_this_address: 'التوصيل لهذا العنوان',
  add_new_address: 'إضافة عنوان جديد',
  back: 'العودة',
  message_confirm_switch_company: 'هل أنت متأكد أنك تريد تغيير الشركة الحالية وتنتقل الى',
  change_company: 'تغيير الشركة',
  search_for_location: 'ابحث عن الموقع',
  address: 'العنوان',
  address_name: 'اسم العنوان',
  locate_me: 'حدد موقعى',
  your_location: 'موقعك الحالى',
  Shipping_fees: 'مصاريف الشحن',
  pending: 'قيد اللإنتظار',
  confirmed: 'تم التأكيد',
  delivered: 'تم التوصيل',
  cancelled: 'ملغى',
  order_number: 'رقم طلب الشراء',
  Order_date: 'تاريخ الطلب',
  Amount: 'المبلغ',
  order_status: 'حالة الطلب',
  Payment: 'حالة الدفع',
  paid: 'تم الدفع',
  notpaid: 'لم يتم الدفع',
  paid_invoice: 'فواتير مدفوعة',
  notpaid_invoice: 'فواتير غير مدفوعة',
  file_download: 'تحميل الملفات',
  delivery_note: 'وثيقه التوصيل ',
  All: 'الكل',
  End_date_error_msg: 'يجب أن يكون تاريخ النهاية مساويًا لتاريخ البداية أو بعده',
  Start_date: 'تاريخ البداية',
  End_date: 'تاريخ النهاية',
  Search_by_order_number: 'البحث برقم الطلب',
  renew_quotation: 'أعادة طلب',
  Search_by_invoice_number: 'البحث برقم الفاتورة',
  invalid_date: 'التاريخ غير صحيح',
  Deactivated_successfully: ' تم إلغاء التفعيل بنجاح ',
  Deactivate: ' إلغاء التفعيل',
  delete_Tag_confirm_message: 'هل أنت متأكد أنك تريد إلغاء تفعيل هذة العلامة؟',
  future_date_not_allowed: 'التاريخ المستقبلي غير مسموح به',
  orders_no_result: 'لا توجد طلبات تطابق المعايير التي اخترتها.',
  invoices_no_result: 'لا توجد فواتير تطابق المعايير التي اخترتها.',
  shipping_fees: 'مصاريف الشحن',
  building: 'المبنى',
  contact_person_name: 'أسم المسؤول عن التواصل',
  credit_debit_card: 'بطاقة بنكية',
  support_paymaent_cards_msg: 'يمكنك إستخدام بطاقات ماستر كارد / فيزا / مدى',
  you_can_use_cards: 'يمكنك إستخدام بطاقات ماستر كارد / فيزا / مدى',
  you_can_casr_or_card: 'ادفع نفداً او بالكارد عند إستلام طلبك',
  cash_on_delivery: 'الدفع عند الاستلام',
  pay_with_cash: 'ادفع نفداً او بالكارد عند إستلام طلبك',
  agreement: 'إتفاقية اطارية',
  credit_account: 'حساب الائتمان',
  credit_account_description: 'حدد الاتفاقية الموقعة من القائمة',
  payment_options: 'وسائل الدفع',
  payment_methods: 'وسائل الدفع',
  sign_in: 'سجل الأن',
  to_get_started: 'للبدء',
  Welcome_to_LAWAZEM: 'مرحباً فى لوازم',
  complete_order: 'تأكيد الطلب',
  add_new_card: 'أضافة بطاقة دفع جديدة',
  thank_you_for_order: 'شكرا لطلبك!',
  thank_you_for_placing_order:
    'شكرًا على إستكمال طلبك, سنرسل لك إشعارًا عند الشحن. إذا كان لديك أي سؤال أو استفسار فلا تتردد في الاتصال بنا',
  go_to_orders: 'متابعة الطلبات',
  order_status_not_valid:
    'حدث خطأ ما أثناء معالجة طلب الدفع ، يرجى المحاولة مرة أخرى أو أختيار طريقة دفع أخرى',
  order_status_valid:
    'تم إرسال طلبك بنجاح و طلب الدفع الخاص بكم قيد الاستكمال يرجى التحقق لاحقا بعد 5 دقائق',
  address_not_available: 'لا يوجد عناوين',
  try_again: 'حاول مجددا',
  select_another_payment_method: 'اختر وسيلة دفع اخرى',
  update_payment: 'تعديل طريقة الدفع',
  payment_updated_successfully: 'تم تعديل طريقة الدفع بنجاح',
  order_created_successfully: 'تم انشاءالطلب بنجاح',
  free: 'مجانى',
  due_balance: 'المبلغ المستحق:',
  print_quotation: 'طباعة الطلب',
  question_mark: '؟',
  delivery_addresses: 'عناوين التسليم',
  address_details: 'تفاصيل العنوان',
  no_orders_yet: 'لا يوجد طلبات حاليا!',
  no_invoices_yet: 'لا يوجد فواتير حاليا!',
  payment_status: 'حالة الدفع',
  payment_method: 'طريقة الدفع',
  by_card: 'بالبطاقة البنكية',
  cash: 'نقدي',
  view_order_details: 'يمكنك عرض تفاصيل طلبك',
  Coupon_code: 'كود خصم',
  apply: 'تفعيل',
  Enter_promo_code_here: 'أدخل كود الخصم',
  Coupon_discount: 'كود الخصم',
  Coupon: 'كود',
  here: 'هنا',
  close: 'غلق',
  agreement_txt: 'إتفاقية',
  Vat_short_text: 'الضريبة',
  not_allowed_before_1900: 'غير مسموح بإختيار تاريخ قبل 1900',
  product_details: 'تفاصيل المنتج',
  payment_information: 'معلومات الدفع',
  paid_amount: 'المبلغ المدفوع',
  slider_img_1_title: 'اطلب مشتريات أعمالك',
  slider_img_1_description: 'بكل سهولة وبأفضل سعر',
  billing_details: 'تفاصيل الفوترة',
  new_card: 'أضافة بطاقة دفع جديدة',
  order_agian: 'الطلب مرة أخرى',
  delete_card_confirm_message: 'هل أنت متأكد أنك تريد حذف هذه البطاقة؟',
  PrivacyPolicy: 'سياسة الخصوصية لدينا.',
  please_fill_missing_data: 'الرجاء إستكمال البيانات',
  full_name: 'الاسم الكامل',
  edit_address: 'تعديل العنوان',
  delete_address_confirm_msg: 'هل أنت متأكد أنك تريد حذف هذا العنوان؟',
  pack_type: 'وحدة الشراء',
  invoice_number: 'رقم الفاتورة',
  invoice_date: 'تاريخ الفاتورة',
  due_date: 'التاريخ المستحق',
  products_will_deleted_alert: 'تنبيه! المنتجات الاتية غير متاحة حاليا, وسيتم حذفها من سلةالتسوق',
  products_packtype_updated_alert:
    'تنبيه! تم تحديث وحدات الشراء الخاصة بالمنتجات الاتية . يرجى مراجعتها  قبل المتابعة في طلبك',
  products_not_avaliable_alert: 'يرجى العلم أنه في الوقت الحالي لا يمكن إعادة طلب المنتجات التالية',
  products_price_changed_alert: 'و المنتجات الاتية قد تغير سعرها',
  select_payment_card: 'اختر بطاقة دفع',
  purchase_Order: 'طلب الشراء',
  didnot_find_what_you_want: 'لم تجد ما تبحث عنه؟',
  provide_description: 'قم بكتابة وصف لكل منتج تريده وسنبذل قصارى جهدنا لتوفيره لك.',
  enter_email_quotation: 'أدخل البريد الإلكتروني الذي سيتم إرسال عرض السعر إليه',
  tell_us_looking_for: 'أخبرنا عما تبحث عنه؟',
  accept_max_500: 'مسموح بحد أقصي 500 حرف',
  sorry: 'عفواً !',
  product_not_found: 'هذا المنتج غير متاح حالياً',
  go_to_marketplace: 'الذهاب إلى المتجر',
  ok: 'تم',
  credit_account_purchase_order: 'حساب ائتمان شراء الطلب',
  credit_account_purchase_order_desc:
    'استخدم حساب الائتمان المعتمد الخاص بك عن طريق تحميل طلب الشراء',
  please_aware_products_price_changed_alert: 'يرجى العلم أنه المنتجات الاتية قد تغير سعرها',
  select_address: 'اختر عنوان ',
  no_address_options: 'لا يوجد عناوين متاحه',
  no_options: 'لا يوجد اجابات متاحه',
  no_select_options: 'لا يوجد اختيارات متاحه',
  requestForQuotation: 'طلب تسعير',
  new_request: 'طلب جديد',
  add_attachment: 'اضافة مرفق',
  submit_request: 'إرسال الطلب',
  quotation: 'طلب تسعير',
  chat_history: 'تاريخ المحادثات',
  in_progress: 'قيد التنفيذ',
  convert_to_order: 'تحويل الي طلب',
  quotation_date: 'تاريخ الطلب:',
  quoted: 'تم التسعير',
  expired: 'منتهي الصلاحية',
  rfq_number: 'طلب تسعير',
  request_date: 'تاريخ الطلب',
  expiry_date: 'تاريخ الانتهاء',
  exp: 'تاريخ الانتهاء',
  add_attachments: 'اضافة مرفقات',
  no_order_lines_to_display: 'ﻻ يوجد منتجات',
  order_lines: 'المنتجات',
  Submitted: 'تم إرساله',
  order: 'الطلب',
  discussion: 'مناقشة',
  type_a_message: 'أكتب رسالتك',
  message: 'رسالتك',
  accepted_files_hint: 'صيغة الملفات المقبولة',
  files_extenation: '[.pdf, .jpeg, .png, .doc, .docx]',
  request_submitted_successfully: 'تم تقديم الطلب بنجاح',
  something_went_wrong_with_your_selected_company:
    'حدث خطأ ما في الشركة التي اخترتها، يرجى اختيار شركة أخرى',
  something_went_wrong_with_your_account:
    'حدث خطأ ما في حسابك، يرجى التواصل مع فريق خدمة عملاء لوازم',
  discount: 'الخصم',
  rfq_no_access_message:
    'عذرًا!، لا يمكنك إرسال طلبك نظرًا لأن شركتك ليس لديها الإذن لإنشاء طلبات عروض الأسعار',
  credit_not_processing: 'لا يمكن للرصيد الائتماني لشركتك معالجة هذا الطلب',
  contract_remaining_balance: 'رصيد عقد للشركة لايسمح بتنفيذ الطلب',
  credit_limit: 'الحد الائتماني',
  remaining_balance: 'الرصيد المتبقى',
  remaining:'الرصيد المتبقى',
  unpaid_invoices: 'الفواتير الغير مدفوعة',
  for_more_information: 'لمزيد من المعلومات يرجى الاتصال بقسم المبيعات على',
  view_all: 'عرض الكل',
  Market: 'ماركت',
  Express: 'إكسبريس',
  products_pack_type_changed_alert:
    'تم تغيير وحدة الشراء الخاصة بالمنتجات الاتية , يرجي مراجعتها قبل استكمال الطلب',
  notifications: 'تنبيهات',
  no_notifications_yet: 'لا توجد تنبيهات في الوقت الحالي',
  you_have_no_notifications: 'ليس لديك أي إشعارات الآن يرجى التحقق مرة اخرى في وقت لاحق',
  invoices_s: 'فواتير',
  invoice: 'فاتورة',
  mobile_redirection_message: 'الموقع يدعم الاجهزه ذات الشاشات الكبيرة لضمان افضل تجربة استخدام',
  download_our_app: 'قم بتنزيل التطبيق',
  starter_pack: 'باقات المكتب الاساسية',
  sign_up: 'انشاء حساب',
  filter_by_categories: 'تصنيف حسب الفئات',
  filter_by: 'تصنيف حسب',
  Pay_Invoice: 'دفع الفاتورة',
  due_amount: 'المبلغ المستحق',
  unpaid: 'لم يتم الدفع',
  overdue: 'فواتير متأخره',
  no_invoices_available_yet: 'لا يوجد فواتير متاحة حالياً',
  partially_paid: 'مدفوعة جزئيا',
  date_type: 'نوع التاريخ',
  select_agreement_contract: 'اختر العقد',
  no_available_contracts: 'لا يوجد عقود متاحة',
  Select_payment_method:'اختر وسيلة دفع',
  No_Available_agreement:'لا يوجد عقود متاحة',
  the_agreement: 'الاتفاقية',
  add_list_of_products: 'Add list of products ar',
  new_rfq: 'طلب تسعير جديد',
  new_RFQ: 'طلب تسعير جديد',
  LoginToGetTheBestExperience: 'سجل الدخول للإستمتاع بأفضل تجربة',
  SpendingsReport: 'تقرير الإنفاقات',
  ProductInvoicingSummaryReport: 'تقرير ملخص الفواتير الخاص بالمنتجات',
  NoDataFound: 'لا توجد بيانات لعرضها',
  AnalyticsTags: 'إحصائيات الطلبات',
  Tag: 'تصنيف الطلب',
  OrdersCount: 'عدد الطلبات',
  OrdersTotalAmount: 'إجمالي مبلغ الطلبات',
  Active: 'مفعل',
  AddNewTag: 'أضف تصنيف جديد',
  no_tags_to_display: 'لا يوجد تصنيفات لعرضها',
  select_new_tag: 'أختر تصنيف',
  my_catalog: 'قائمة المنتجات الخاصة بى',
  stores: 'متاجر',
  Invite_your_Colleague_to: 'أضف زميلك الي',
  Invite: 'دعوة',
  Send: 'إرسال',
  Invitaion_content: 'سوف يتلقى إشعارًا عبر الرسائل القصيرة مع رابط لتسجيل الدخول.',
  Invitaion_content2:
    'قم بدعوة زميلك للانضمام إلى منصتنا وتعيينه إلى شركتك بسهولة عن طريق ملء التفاصيل أدناه.',
  InvitationSentSuccessfully: 'تم ارسال الدعوة بنجاح',
  NotActive: 'غير مفعل',
  Add: 'أضف',
  financial_info: 'المعلومات المالية',
  finances: 'الشؤون المالية',
  your_financial_info: 'شؤونك المالية',
  total_unpaid_invoice: 'إجمالي الفواتير غير المسددة',
  oldest_unpaid_invoice: 'عمر أقدم فاتورة غير مسددة',
  credit_limit_company: 'حد الائتمان للشركة',
  Express_Fees: 'رسوم خدمة إكسبريس',
  AddTag: 'أضف تصنيف',
  days: 'ايام',
  remaining_credit_limit: 'حد الائتمان المتبقى',
  consumed: 'تم استهلاك',
  of: 'من',
  you_exceed_the_limit: 'تم تخطى الحد الاقصى',
  Donnt_Have_account: 'ليس لديك حساب؟',
  Lawazem_is_better_on_the_app: 'لوازم أفضل في التطبيق',
  open_in_the_lawazem_app: 'افتح في تطبيق لوازم',
  settings: 'الاعدادات',
  manage_company: 'ادارة الشركة',
  add_delivery_address: 'اضافة عنوان',
  orders_history: 'تاريخ الطلبات',
  placed_on: 'تاريخ الطلب',
  my_companies: 'شركاتى',
  invite_colleague: 'دعوة',
  my_cart: 'مشترياتى',
  proceed: 'استكمال',
  pick_delivery_address: 'اختر عنوان',
  enter_note_here: 'اكتب ملاحظتك',
  order_details: 'تفاصيل الطلب',
  payment_details: 'بيانات الدفع',
  place_your_order: 'استكمال طلبك',
  saved_cards: 'البطاقات المحفوظة',
  items: 'منتجات',
  no_items: 'ﻻ توجد منتجات',
  create_list: 'أضافة قائمة جديدة',
  select_all: 'اختر الكل',
  update: 'تحديث',
  UpdateProductQuantity: 'تحديث كمية المنتج',
  product_list: 'قائمة المنتجات',
  tags: 'التصنيفات',
  AddSelectedItemsToCart: 'إضافة العناصر المحددة إلى عربة التسوق',
  update_list: 'تحديث القائمة',
  unselect_all: 'إلغاء تحديد الكل',
  select_payment_method: 'اختر وسيلة دفع',
  ChatWithSalesTeam: 'تحدث مع فريق المبيعات',
  Chat:'المحادثات',
  start_chat: 'ابدأ المحادثه',
  request: 'طلب',
  active_orders: 'احدث الطلبات',
  show_all: 'عرض الكل',
  latest_products: 'احدث المنتجات',
  select_tag: 'اختر التصنيف',
  ViewDetails: 'تفاصيل',
  add_to_reorder_list: 'أضف الي قائمة',
  remove_from_all: 'ازالة من الكل',
  remove: 'حذف',
  go_to_reorder_list: 'الذهاب الى القائمة',
  Privacy: 'خصوصية',
  about: 'من نحن',
  Profile: 'إدارة حساب المستخدم',
  notes: 'ملاحظات',
  open: 'فتح',
  Shop_online_for: 'أشتري أونلاين من',
  card_ending_with: 'بطاقة تنتهي بـ',
  subscription_management: 'إدارة الاشتراكات',
  subscriptions: 'الاشتراكات',
  add_new: 'اضافة جديدة',
  add_new_subscription: 'اضافة اشتراك جديد',
  next_renew_date: 'تاريخ التجديد القادم',
  subscription_type: 'نوع الاشتراك',
  subscription_payment_card: 'بطاقة دفع الاشتراك',
  card_holder_name: 'اسم حامل البطاقة',
  valid_thru: 'تاريخ الصلاحية',
  security_code: 'رمز الأمان',
  type: 'النوع',
  status: 'الحالة',
  redirect_cart_message: 'تم إنشاء طلبك بنجاح! يرجى إكمال الدفع للمتابعة. إكمال الدفع',
  go_to_order: 'متابعة الطلب',
  Exp: 'تاريخ الانتهاء',
  Your_chat_history_is_empty: 'لا توجد محادثات سابقة',
  Renewal: 'التجديد',
  see_more: 'عرض المزيد',
  see_less: 'عرض الأقل',
  add: 'أضف',
  not_allowed_adding_address:
    'يمكن فقط لمسؤولي الشركة إضافة عنوان. يرجى الاتصال بمسؤولك لإضافته أو تحديثه',
  submit_for_approval: 'تقديم للموافقة',
  approval_request_has_been_sent: 'تم إرسال طلب الموافقة بنجاح',
  follow_updates: 'متابعة التحديثات من قائمة الطلبات',
  selecting_payment_after_approval:
    'هذه الخطوة لاختيار طريقة الدفع فقط، سيتم إتمام الدفع بعد الموافقة',
  ChatWithUs: 'تواصل معانا',
  waiting_approval: 'في انتظار الموافقة',
  invalid_order: 'طلب غير صالح',
  unable_proceed_order_msg: 'نحن غير قادرين على متابعة طلبك للموافقة عليه بسبب المشكلات التالية:',
  Rejection_Reason: 'سبب الرفض',
  Please_write_your_rejection_reason: 'برجاء كتابة سبب الرفض',
  Confirm: 'تأكيد',
  order_has_been_approved: 'تمت الموافقة على الطلب',
  approved: 'تمت الموافقة',
  Pending_approval: 'بانتظار الموافقة',
  Approver: 'بواسطة',
  You_can_send_it_back: 'يمكنك إعادته إلى مقدم الطلب لإجراء المراجعات.',
  send_to_requester: 'إرسال إلى مقدم الطلب',
  to_approve: 'فى انتظارالموافقة',
  rejected: 'مرفوض',
  Created_by:'مقدم الطلب',
  Requester: 'مقدم الطلب',
  Current_WF_Approver:'موافق الطلب الحالى',
  keep_trying:'استمر في الكتابة! يرجى إدخال أكثر من 2 أحرف لعرض نتائج المنتجات.',
  fixed_price_list:'قوائم الاسعار الثابتة',
  mobile_redirect_message:
  'للحصول على تجربة أفضل وعرض التقارير التفصيلية، يرجى فتح هذه الصفحة من خلال إصدار الويب.',
  Go_to_Home: 'الذهاب للرئيسية',
  Reject:'رفض',
  Approve:'قبول',
  myApproval: 'موافقتي',
  teamApproval: 'موافقة الفريق',
};

export default ar;
