import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useLocales } from 'src/locales';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { Box, Tab, Tabs, Grid, Stack, Typography, Dialog } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import logopopup from 'src/assets/shared/logopopup.svg';
import LoginLayout from './LoginLayout';
import LoginForm from './LoginForm';
import Iconify from '../../../components/iconify';
import RegisterForm from '../register/RegisterForm';

export default function GuestPopup({ redirect_url = '', open, onClose, ...other }: any) {
  const theme = useTheme();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const [currentTab, setCurrentTab] = useState('1');
  const current_url = redirect_url;
  if (current_url) {
    localStorage.setItem('redirectUrl', current_url);
  }
  const handleClose = () => {
    localStorage.removeItem('redirectUrl');
    onClose();
  };
  return (

    <Dialog fullWidth={!isDesktop}
      maxWidth={isDesktop ? 'md' : 'lg'}
      onClose={handleClose}
      open={open} {...other}
    >
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item sx={{ width: { xs: '100%', lg: '50%' } }}>
          <LoginLayout
            showHeader={false}
            children={undefined}
            title={`${translate('auth.slogan')}`}
          />
        </Grid>
        <Grid item sx={{ width: { xs: '100%', lg: '50%' }, pb: 6, px: 6, overflow: 'auto' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[900],
            }}
          >
            <Iconify width={24} icon="material-symbols:close-small-outline-rounded" />
          </IconButton>
          <Box sx={{ my: 2 }}>
            {!isDesktop && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                component="img"
                src={logopopup}
                sx={{ padding: '0 15px', height: 22, cursor: 'pointer' }}
              />
            </Box>
            }
            <Stack sx={{ alignItems: 'center', mb: 4, mt: 3 }}>
              <Typography variant="h4">{`${translate('Welcome_to_LAWAZEM')}`}</Typography>
              <Typography
                variant="h6"
                sx={{ color: 'text.secondary', fontWeight: 500 }}
              >{`${translate('LoginToGetTheBestExperience')}`}</Typography>
            </Stack>
            <Box mb={7} px={2}>
              <Tabs
                onChange={(event, newValue) => setCurrentTab(newValue)}
                variant="fullWidth"
                value={currentTab}
                TabIndicatorProps={{ hidden: true }}
                centered
                sx={{
                  minHeight: 40,
                  backgroundColor: 'primary.main',
                  borderRadius: 12,
                  padding: 0.5,

                  '& button': {
                    borderRadius: 12,
                    backgroundColor: 'primary.main',
                    padding: '5px',
                    minHeight: '32px',
                    fontSize: '16px',
                    fontWeight: 500,
                  },
                  '& button.MuiButtonBase-root': {
                    color: 'background.default',
                  },
                  '& button:hover': {
                    backgroundColor: 'primary.main',
                    color: 'background.default',
                  },
                  '& button.MuiButtonBase-root.Mui-selected': {
                    backgroundColor: 'background.default',
                    color: 'primary.main',
                  },
                  '& button:focusVisible': {
                    backgroundColor: 'primary.main',
                    color: 'background.default',
                  },
                }}
              >
                <Tab label={`${translate('sign_up')}`} value="2" />
                <Tab label={`${translate('auth.login')}`} value="1" />
              </Tabs>
            </Box>
          </Box>
          {currentTab === '1' ? (
            <LoginForm translate={translate} />
          ) : (
            <RegisterForm translate={translate} />
          )}
        </Grid>
      </Grid>
    </Dialog>

  );
}
