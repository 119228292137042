import * as Yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';

const isValidTel = (value: any) => matchIsValidTel(value);
const LoginSchema = (translate: any) => Yup.object().shape({
    PhoneNumber: Yup.string()
      .required(translate('form_validation.mandatory'))
      .min(13, translate('form_validation.the_phone_number_should_contain_9_numbers'))
      .max(13, translate('form_validation.the_phone_number_should_contain_9_numbers'))
      .matches(
        /^(00966|966|\+966)(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{3})([0-9]{4})$/,
        translate('form_validation.valid_saudi_mobile')
      )
      .test('is-valid-tel', translate('form_validation.valid_saudi_mobile'), isValidTel),
  });

export default LoginSchema;