import _ from 'lodash';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { FormValuesProps } from 'src/@types/auth/register';
import { IOtpForm } from 'src/@types/auth/mobile_verification';
import {
  getClientToken,
  getRefreshToken,
  TokenService,
  setLoginData,
  setLogout,
  authPaths,
} from 'src/utils/API/Helpers';
import { useLocales } from 'src/locales';
import { PATH_AUTH } from 'src/routes/paths';
import { LoginFormsProps } from 'src/@types/auth/login';
import { logSentryError } from 'src/sentry-logger';
import { axiosAuthInstance, axiosInstance, jwtDecode } from '../utils/API/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType, OtpType } from './types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  OTP = 'OTP',
  RESETISOTPSENT = 'ResetIsOtpSent',
  setLogin = 'setLogin',
  ChangeCompanyRequired = 'ChangeCompanyRequired'
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    changeCompanyRequired?: boolean;
    companyData?: any;
  };
  [Types.LOGIN]: {
    otpData: OtpType;
    isOtpSent: boolean;
    companyData?: any;
  };
  [Types.setLogin]: {
    user: {
      accessToken: any;
      refreshToken: any;
    };
    isAuthenticated: boolean;
    changeCompanyRequired: boolean;
  };
  [Types.REGISTER]: {
    otpData: OtpType;
    isOtpSent: boolean;
    companyData?: any;
  };
  [Types.LOGOUT]: undefined;
  [Types.OTP]: {
    companyData: any;
    isAuthenticated: boolean;
  };
  [Types.RESETISOTPSENT]: {
    isOtpSent: false;
    companyData?: any;
  };
  [Types.ChangeCompanyRequired]: {
    changeCompanyRequired: boolean;
    companyData?: any;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: {
    access_token: TokenService.getLocalAccessToken,
    refresh_token: TokenService.getLocalRefreshToken,
    client_token: TokenService.getLocalClientToken,
  },
  otpData: null,
  isOtpSent: false,
  companyData: [],
  changeCompanyRequired: false
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isOtpSent: false,
      user: action.payload.user,
      companyData: action.payload.companyData,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: false,
      otpData: action.payload.otpData,
      isOtpSent: action.payload.isOtpSent,
      companyData: action.payload.companyData,
    };
  }
  if (action.type === Types.setLogin) {
    return {
      ...state,
      user: action.payload.user,
      isAuthenticated: action.payload.isAuthenticated,
      changeCompanyRequired: false
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: false,
      otpData: action.payload.otpData,
      isOtpSent: action.payload.isOtpSent,
      companyData: action.payload.companyData,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: {},
      isOtpSent: false,
    };
  }
  if (action.type === Types.RESETISOTPSENT) {
    return {
      ...state,
      isOtpSent: false,
    };
  }
  if (action.type === Types.OTP) {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      isOtpSent: false,
      companyData: action.payload.companyData,
    };
  }
  if (action.type === Types.ChangeCompanyRequired) {
    return {
      ...state,
      changeCompanyRequired: action.payload.changeCompanyRequired
    };
  }


  return state;
};

export const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentLang } = useLocales();

  const storageAvailable = localStorageAvailable();
  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const data = await getClientToken();

      if (accessToken && isValidToken(accessToken)) {
        if (!accessToken) {
          const tokens = {
            'refresh token': TokenService?.getLocalRefreshToken,
            'access token': TokenService?.getLocalAccessToken,
          };
          logSentryError('can`t get access token', tokens);
        }
        setSession(accessToken);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user: null,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: {
              client_token: data,
            },
            companyData: undefined,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    axiosAuthInstance.defaults.headers['Accept-Language'] = currentLang.value;
    axiosInstance.defaults.headers['Accept-Language'] = currentLang.value;
  }, [currentLang]);

  const login = useCallback(async (formData: LoginFormsProps) => {
    const response = await axiosAuthInstance.post('/Users/send-otp', formData);
    const { data, message, success: isSuccess } = response.data;

    dispatch({
      type: Types.LOGIN,
      payload: {
        otpData: {
          otp: data,
          message,
          phoneNumber: formData.PhoneNumber,
          counter: 59,
          isLogin: formData.isLogin,
          impersonate: formData.impersonate
        },
        isOtpSent: isSuccess,
        companyData: undefined,
      },
    });
    if (!isSuccess) {
      throw new Error(message);
    }
    return response;
  }, []);

  const register = useCallback(async (formData: FormValuesProps) => {
    const response = await axiosAuthInstance.post('/Company/signup-v2', formData);
    const { data, message } = response.data;

    dispatch({
      type: Types.REGISTER,
      payload: {
        otpData: {
          otp: data,
          message,
          phoneNumber: formData.PhoneNumber,
          counter: 59,
          isLogin: false,
        },
        isOtpSent: true,
        companyData: undefined,
      },
    });
    return response;
  }, []);

  const getUserToken = useCallback(async (formData: any) => {
    const responseObj = await axiosAuthInstance.post('/Users/generate-token-v2', formData);
    const dataObj = responseObj.data.data;
    const { accessToken, refreshToken, reFreshTokenExpiration } = dataObj;

    dispatch({
      type: Types.setLogin,
      payload: {
        user: {
          accessToken,
          refreshToken,
        },
        isAuthenticated: !!accessToken,
        changeCompanyRequired: false
      },
    });
    setLoginData({
      accessToken,
      refreshToken,
      reFreshTokenExpiration
    });
    return !!accessToken;
  }, []);

  const setChangeCompanyRequires = useCallback(async () => {
    dispatch({
      type: Types.ChangeCompanyRequired,
      payload: {
        changeCompanyRequired: false
      },
    });
  }, []);

  const mobile_verification_login = useCallback(
    async (formData: IOtpForm) => {
      localStorage.setItem("num45", `${formData?.otp}`);
      const response = await axiosAuthInstance.post('/Users/check-otp', formData);
      const { data, message, success: isSuccess } = response.data;

      const success = isSuccess && data.length >= 1;
      if (!success) {
        throw new Error(message);
      }

      dispatch({
        type: Types.OTP,
        payload: {
          companyData: data,
          isAuthenticated: false,
        },
      });
      return response;
    },
    []
  );

  const mobile_verification_register = useCallback(
    async (formData: IOtpForm) => {
      const response = await axiosAuthInstance.post('Company/active-company-by-otp_v2', formData);
      const {
        data: { accessToken, refreshToken, reFreshTokenExpiration, companies: companyData },

        success: isSuccess,
      } = response.data;
      dispatch({
        type: Types.OTP,
        payload: {
          companyData,
          isAuthenticated: isSuccess,
        },
      });
      setLoginData({
        accessToken,
        refreshToken,
        reFreshTokenExpiration,
      });
      return response;
    },
    []
  );

  const logout = useCallback((afterLogoutUrl?: any) => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
    setLogout(afterLogoutUrl);
  }, []);

  const ResetIsOtpSent = useCallback(() => {
    dispatch({
      type: Types.RESETISOTPSENT,
      payload: {
        isOtpSent: false,
      },
    });
  }, []);

  let isRefreshing = false;
  let isRefreshingDateTime = Date.now();
  const retries = 1;
  let refreshQueue: any[] = []
  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const {
        config: orgConfig,
        response,
      } = err
      // Check if it's 401
      if (response?.status === 401) {

        orgConfig._retry = typeof orgConfig._retry === 'undefined' ? 0 : (orgConfig._retry + 1);
        if (orgConfig._retry === retries) {
          return Promise.reject(err)
        }
        const currentDateTime = Date.now();
        if (!isRefreshing && (currentDateTime > (isRefreshingDateTime + 3))) {
          isRefreshingDateTime = currentDateTime;
          isRefreshing = true;
          if (!TokenService.getLocalRefreshToken || !TokenService.getLocalAccessToken) {
            const tokens = {
              'refresh token': TokenService?.getLocalRefreshToken,
              'access token': TokenService?.getLocalAccessToken,
            };
            logSentryError('calling api that returns 401 and there is no access or refresh token', tokens);
            return logout();
          }
          try {
            const { accessToken } = await getRefreshToken();
            if (!authPaths[orgConfig.url]) {
              orgConfig.headers.Authorization =
                !!TokenService.getLocalAccessToken && `Bearer ${accessToken}`;
            }
            if (accessToken) {
              refreshQueue.forEach((v) => v.resolve());
            }
            refreshQueue = [];
            isRefreshing = false
            return await axiosInstance(orgConfig);
          } catch (_err) {
            refreshQueue.forEach((v) => v.reject(err));
            refreshQueue = [];
          }
          isRefreshing = false;
        } else {

          return new Promise((resolve, reject) => {
            refreshQueue.push({
              resolve: (res: any) => {
                const config = _.merge(orgConfig, res);
                resolve(axiosInstance(config));
              },
              reject: (error: any) => {
                reject(error)
              },
            })
          })
        }
        return err;
      }
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      if (+response?.status === 400) {
        console.error('server error, please try again');
      }
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      if (+response?.status === 404) {
        if (response?.data?.code === 538) {
          dispatch({
            type: Types.ChangeCompanyRequired,
            payload: {
              changeCompanyRequired: true,
              companyData: state.companyData,

            },
          });
        }
      }
      return Promise.reject(err);
    }
  );

  axiosAuthInstance.interceptors.response.use(
    (res) => {
      if (res?.config?.url?.toLowerCase() === 'auth/gettoken') TokenService?.updateLocalClientToken(res?.data?.data);
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      // Check if it's 401
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      if (+err?.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          // it's 401 And unAuthenticated
          /* eslint-disable-next-line no-extra-boolean-cast */
          await getClientToken();
          return await axiosAuthInstance(originalConfig);
        } catch (_err) {
          logSentryError('can`t get client token');
          return logout();
        }
      }
      return Promise.reject(err);
    }
  );


  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      otpData: state.otpData,
      isOtpSent: state.isOtpSent,
      companyData: state.companyData,
      changeCompanyRequired: state.changeCompanyRequired,
      setChangeCompanyRequires,
      login,
      register,
      mobile_verification_register,
      mobile_verification_login,
      logout,
      ResetIsOtpSent,
      initialize,
      getUserToken,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.otpData,
      state.isOtpSent,
      state.companyData,
      state.changeCompanyRequired,
      setChangeCompanyRequires,
      login,
      logout,
      mobile_verification_register,
      mobile_verification_login,
      register,
      ResetIsOtpSent,
      initialize,
      getUserToken,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
