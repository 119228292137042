export const  MarketTagEnum={
  "Stock":"Stock",
  "JustInTime":"Just-In-Time"
}

export const  ItemType={
  "Category":1,
  "Section":2
}

export const InvoicesStatusEnum = {
  1: {
    name: 'status_not_paid,',
    color: 'warning',
    opacity: "0.16"
  },
  2: {
    name: 'in_payment',
    color: 'success',
    opacity: "0.12"
  },
  3: {
    name: 'paid',
    color: 'success',
    opacity: "0.12"
  },
  4: {
    name: 'partial',
    color: 'primary',
    opacity: "0.12"
  },
  5: {
    name: 'reversed',
    color: 'default',
  },
  6: {
    name: 'invoicing_legacy',
    color: 'info',
  },
};

export const actionNameTypes ={
  'lang' : 'lang',
  'logout' : 'logout',
  'openChat' : 'openChat',
}