import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga4';
import Router from './routes';
import { UserContext } from './utils/Contexts/UserContext/UserProvider/UserProvider';

import { firebaseAnalytics, segmentAnalytics } from './config-firebase';
import ProductFruits from './components/product-fruits';

const hotjarOptions: any = {
  id: process.env.REACT_APP_HOTJAR_TOKEN_ID,
};

const ConfigInitialize = () => {
  const location = useLocation();
  const pagePathName = location.pathname;
  const { UserInfo } = useContext(UserContext);
  const { fetchAuthData, isAuthenticated } = useContext(UserContext);
  const { href } = window.location;
  if (href.search('&amp;') > -1) {
    window.location.href = href.replace('&amp;', '&');
  }

  useEffect(() => {
    if (isAuthenticated()) fetchAuthData();
  }, [location, isAuthenticated, fetchAuthData]); // Call fetchData whenever the route changes

  // HOTJAR
  if (process.env.REACT_APP_IS_HOTJAR_ENABLED === 'true') {
    hotjar.initialize(hotjarOptions);
    if (hotjar.initialized()) {
      hotjar.identify('USER_ID', { userProperty: UserInfo?.id });
      // Update SPA state
      hotjar.stateChange(pagePathName);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_IS_ANALYTICS_ENABLED === 'true') {
      // @ts-ignore:next-line
      firebaseAnalytics.logEvent(`Lawazem_page_${pagePathName}`);
    }
  });

  useEffect(() => {
    if (process.env.REACT_APP_IS_SEGMENT_ENABLED === 'true') {
      const gaData = {
        companyId: UserInfo?.companyId || 'Guest_User',
        userId: UserInfo?.id || 'Guest_User',
        source: 'web',
      };
      segmentAnalytics.page(gaData);
    }
    ReactGA.initialize('G-XXLBTHD62G');
  }, [UserInfo?.companyId, UserInfo?.id]);

  return (
    <>
      {Object.values(UserInfo).length && <ProductFruits userInfo={UserInfo} />}
      <Router />;
    </>
  );
};

export default ConfigInitialize;
