import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Iconify from 'src/components/iconify';
import { CartContext } from 'src/utils/Contexts/Cart/CartProvider';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { NotificationsContext } from 'src/utils/Contexts/Notifications/NotificationsProvider';
import useResponsive from '../../hooks/useResponsive';
import { useSettingsContext } from '../../components/settings';
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const { refetchCNotifications, canUpdatePageNumber } = useContext(NotificationsContext);
  const location = useLocation();

  const { refetchCartCount } = useContext(CartContext);
  useEffect(() => {
    if (refetchCartCount) {
      refetchCartCount();
    }
    if (canUpdatePageNumber && refetchCNotifications) {
      refetchCNotifications();
    }
  }, [location.key, refetchCartCount, refetchCNotifications, canUpdatePageNumber]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;
  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }
  // Function to determine if we should hide content based on the path
  const shownPaths = ['/categories/', '/search',"/catalog"];
  const path = location.pathname;
  const shouldShowContent = !!shownPaths.find((keyword) => path === '/' || path.includes(keyword));
  const showHomeContent = !!shownPaths.find((keyword) => path === '/');

  return (
    <Box
      sx={{
        position: 'relative',
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        '& *:focus-visible ': {
          outline: 'none;',
        },
      }}
    >
      <Header onOpenNav={handleOpen} shouldShowContent={shouldShowContent} showHomeContent={showHomeContent} />
      {renderNavVertical}
      <Main shouldShowContent={shouldShowContent}>
        <Outlet />
      </Main>
      {/* CART ICON FOR MOBILE VIEW  */}
      {!isDesktop && shouldShowContent && (
        <Box
          onClick={() => PATH_DASHBOARD.CartDetails}
          sx={{
            height: '52px',
            width: '52px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            position: 'fixed',
            bottom: '50px',
            right: '40px',
            zIndex: '1000',
          }}
        >
          <a style={{ height: '32px' }} href={PATH_DASHBOARD.CartDetails}>
            <Iconify icon="mdi:cart-outline" color="white" width={34} />
          </a>
        </Box>
      )}
    </Box>
  );
}
