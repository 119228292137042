import { alpha, Theme } from '@mui/material/styles';
import { DrawerProps } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme) {
  const isDesktop = useResponsive('up', 'lg');

  return {

    anchor: 'bottom',
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: DrawerProps }) => ({
          ...(!isDesktop && {
            '& .MuiBackdrop-root': {
              backgroundColor: alpha(theme.palette.common.black, 0.2),
            },
            '& .MuiPaper-root': {
              borderRadius: '12px 12px 0 0 ',
            },
          }),
          ...(ownerState.variant === 'temporary' && {
            '& .MuiDrawer-paper': {
              boxShadow: `-40px 40px 80px -8px ${alpha(theme.palette.grey[500], 0.24
              )}`,
              ...(!isDesktop && {
                maxHeight: '75%',
              }),
            },
          }),
        }),
      },
    },
  };
}
