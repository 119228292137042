import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import ic_cart from 'src/assets/icons/ic_cart.png';
import {CartContext} from 'src/utils/Contexts/Cart/CartProvider';
import { PATH_DASHBOARD } from '../../routes/paths';

export default function CartWidget() {
  const { cartCount } = useContext(CartContext);
  const [count,setCount]=useState(cartCount);
  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    marginInlineEnd: '6px',
    '& .MuiBadge-badge': {},
  }));

  useEffect(() => {
    setCount(cartCount);
  }, [cartCount]);
  
  return (
    <a href={PATH_DASHBOARD.CartDetails}>
      <StyledBadge badgeContent={count} showZero color="primary">
        <Box component="img" src={ic_cart} sx={{ width: 27, height: 27 }} />
      </StyledBadge>
    </a>
  );
}
