import { Box, Badge, Button, Divider, Typography, Stack, useTheme } from '@mui/material';
import { useLocales } from 'src/locales';
import LawazemIcon from 'src/assets/icons/LawazemIcon';
import { isAndroid } from 'react-device-detect';
import useResponsive from 'src/hooks/useResponsive';

export default function DownlodeApp() {
  const { translate } = useLocales();
  const isTablet = useResponsive('between', 'sm', 'lg');
  const theme = useTheme();

  return (
    <Box
      gap="0 2px"
      flexDirection="row"
      display="flex"
      sx={{
        minHeight: '62px', alignItems: 'center', justifyContent: 'space-between', px: { xs: 2, sm: 3 },
        ...(isTablet && {
          backgroundColor: theme.palette.grey[200],
        }),
      }}
    >
      <Stack sx={{ m: '5px' }} flexDirection='row'>
        <Stack sx={{ mr: 2 }}>
          <LawazemIcon />
        </Stack>
        <Stack>
          <Typography sx={{ fontSize: '13px' }} fontWeight={600} color="primary.newdarker">
            {`${translate('Lawazem_is_better_on_the_app')}`}
          </Typography>
          <Typography
            sx={{ fontSize: '13px' }}
            variant="subtitle1"
            fontWeight={500}
            color="primary.newdarker"
          >
            {`${translate('open_in_the_lawazem_app')}`}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ ml: '25px' }}>
        <Button
          sx={{ width: '65px', height: '34px', padding: '11,22px' }}
          href={
            isAndroid
              ? 'https://play.google.com/store/apps/details?id=com.lawazem.customerapp&pli=1'
              : 'https://apps.apple.com/eg/app/lawazem-%D9%84%D9%88%D8%A7%D8%B2%D9%85/id6444859755'
          }
          variant="contained"
          color="primary"
        >
          <Typography sx={{ fontSize: '12px', fontWeight: '700' }}>
            {`${translate('open')}`}{' '}
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
