import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from '../DatePicker';

export default function RHFDatePicker({ name, helperText, ...other }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          {...other}
          slotProps={{
            textField: {
              helperText: error?.message,
              error: !!error,
            },
          }}
        />
      )}
    />
  );
}
