import { useState } from 'react';
import { MenuItem, Stack, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useLocales } from '../../../locales';
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';

export default function LanguagePopover({ hasText = false }: { hasText?: boolean }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const theme = useTheme();
  const StyledTextRoot = {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    padding: '10px 8px',
    fontSize: '14px',
    fontWeight: '600',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.secondary.contrastText,
  };

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          '&:before': {
            zIndex: 1,
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            position: 'absolute',
          },
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
          ...(hasText && StyledTextRoot),
        }}
      >
        <Image
          disabledEffect
          src={currentLang.icon}
          alt={currentLang.label}
          sx={{ width: 30, height: 30, borderRadius: '50%' }}
        />
        {hasText && (
          <>
            <span>{currentLang.value === 'ar' ? 'AR' : 'EN'}</span>
            <Iconify icon="eva:arrow-ios-downward-fill" />
          </>
        )}
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 32, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
