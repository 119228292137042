import { AnalyticsBrowser } from '@segment/analytics-next';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const writeKey = process.env.REACT_APP_SEGMENT_KEY;

if (process.env.REACT_APP_IS_ANALYTICS_ENABLED === 'true') {
  firebase.initializeApp(FIREBASE_API);
}

export const firebaseAnalytics =
  process.env.REACT_APP_IS_ANALYTICS_ENABLED === 'true' && firebase.analytics();
export const segmentAnalytics = AnalyticsBrowser.load({ writeKey });
