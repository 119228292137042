import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocales } from 'src/locales';
import { ANONYMOUS_PATH_DASHBOARD } from 'src/routes/paths';
import ShopProductSearch from 'src/sections/products_list/ShopProductSearch';
import { CallAPI } from 'src/utils/API/APIConfig';
import { Anonymous_PRODUCTS_LIST } from 'src/utils/API/EndPoints/HomeEp';

const GuestSearchBar = () => {
  const [searchName, setSearchName] = useState(null);
  const { translate, currentLang } = useLocales();
  const navigate = useNavigate();
  const redirectionURL = (name) => {
    navigate(ANONYMOUS_PATH_DASHBOARD.SearchResults.root(name));
  };

  const isSearchable = searchName && searchName?.trim() !== '';

  const { data: productsList, isLoading: isProductLoading, isFetching: isProductFetching, } = CallAPI({
    name: ['GuestHeaderSearch', searchName, currentLang],
    url: Anonymous_PRODUCTS_LIST,
    refetchOnWindowFocus: false,
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    enabled: !!isSearchable,
    query: {
      ProductName: searchName?.trim() || null,
    },
    select: (data) => data?.data?.data?.products,
  });


  return (
    <ShopProductSearch
      products={productsList}
      translate={translate}
      searchName={searchName}
      setSearchName={setSearchName}
      autoHighlight={false}
      width="815px"
      redirectionURL={redirectionURL}
      loading={isProductFetching || isProductLoading}
      placeholder={translate('products_general_search_placeholder')}
      bgColor="primary.lighter10"
      withoutBorder
    />
  );
};

export default GuestSearchBar;
