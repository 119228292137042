
import axios from 'axios';
import axiosRetry from 'axios-retry';


export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}


export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});


export const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
axiosRetry(axiosAuthInstance, {
  retries: 3, retryCondition(error) {
    switch (error?.response?.status) {
      // retry only if status is 500 or 501
      // case 400:
      case 401:
        // case 501:
        return true;
      default:
        return false;
    }
  },
});


