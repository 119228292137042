import { SVGProps } from "react"

const BillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#003648"
      d="m23.812 19.444-3.13-3.84-.11-6.765a.872.872 0 0 0 0-.137C19.584 3.335 16.463.266 11.997.266c-4.716 0-8.505 3.429-9.431 8.419a.857.857 0 0 0 0 .154l-.112 6.859-2.34 3.84a.857.857 0 0 0 0 .858.857.857 0 0 0 .737.446h7.716a3.43 3.43 0 0 0 6.859 0h7.716a.857.857 0 0 0 .772-.489.858.858 0 0 0-.103-.909Zm-11.814 3.113a1.714 1.714 0 0 1-1.715-1.715h3.43a1.714 1.714 0 0 1-1.715 1.715Zm-9.628-3.43 1.714-2.778a.858.858 0 0 0 .12-.428l.078-6.996c.78-4.158 3.875-6.945 7.716-6.945 4.827 0 6.37 4.287 6.858 6.945l.12 6.996c.002.193.068.38.189.531l2.178 2.675H2.37Z"
    />
  </svg>
)
export default BillIcon
