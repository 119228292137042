import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';
import { isValidToken } from './utils';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isInitialized, changeCompanyRequired } = useAuthContext();
  
  if (!localStorage.getItem('deviceId')) {
    const unique_id = uuid();
    localStorage.setItem('deviceId', unique_id);
  }

  if (isValidToken(localStorage?.getItem('accessToken')||'' ) && !changeCompanyRequired) {
    return <Navigate to='/' replace />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
