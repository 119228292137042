import { Alert } from "@mui/material";

const ImpersonateAlert = () => (
    <Alert severity="error" variant="filled"  sx={{
      position: 'fixed',
      width: '100%',
      zIndex: 10000,
      justifyContent: 'center',
      top:0,
      left:0,
      alignItems:'center'
    }}>
      <h1>
        Impersonate!
      </h1>
    </Alert>
  );


export default ImpersonateAlert;
