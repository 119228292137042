import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFTextField } from 'src/components/hook-form';
import PhoneCountryCode from 'src/components/hook-form/PhoneCountryCode';
import { FormValuesProps, RegisterProps } from 'src/@types/auth/register';
import { useAuthContext } from 'src/auth/useAuthContext';
import RegisterSchema from 'src/utils/forms/validationSchema/Register';
import { getBrowserInfo } from 'src/utils/commonFunction';
import { useNavigate, useParams } from 'react-router';
import { PATH_AUTH } from 'src/routes/paths';
import { segmentAnalytics } from 'src/config-firebase';

export default function RegisterForm({ translate }: RegisterProps) {
  const { register } = useAuthContext();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const defaultValues = {
    FullName: '',
    PhoneNumber: undefined,
    CompanyName: '',
    email: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema(translate)),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const freshSalesParams = {
      utm_source: searchParams.get('utm_source'),
      utm_medium: searchParams.get('utm_medium'),
      utm_campaign: searchParams.get('utm_campaign'),
      utm_id: searchParams.get('utm_id'),
    };

    try {
      if (register) {
        const response = await register({
          ...data,
          PhoneNumber: `${data?.PhoneNumber?.toString().replace('+', '00').replace(/\s/g, '')}`,
          deviceType: getBrowserInfo(),
          ...freshSalesParams,
        });
        const { success: isSuccess } = response.data;

        segmentAnalytics.track('Sign_Up');
        if (isSuccess) {
          navigate(`${PATH_AUTH.otp}`);
        }
      }
    } catch (error) {
      const errCode = error?.response?.data?.code;

      // eslint-disable-next-line
      if (errCode == 503) {
        setError('PhoneNumber', {
          type: 'server',
          message: translate('form_validation.phone_already_exists'),
        });
      }
      setError('afterSubmit', {
        ...error,
        message: error.message || error,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        <RHFTextField name="FullName" label={translate('full_name')} autoFocus />

        <PhoneCountryCode name="PhoneNumber" />
        <RHFTextField name="email" label={`${translate('form.email_address')}`} />
        <RHFTextField name="CompanyName" label={translate('form.company_name')} />

        <Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitSuccessful || isSubmitting}
            sx={{ mt: 2 }}
          >
            {`${translate('signup.create_account')}`}
          </LoadingButton>
          <Stack sx={{ mt: '20px', alignSelf: 'center', display: 'flex' }}>
            <Typography sx={{ fontWeight: '400', fontSize: '12px', color: 'text.secondary' }}>
              {`${translate('By_signing_up_i_agree_to')}`}
              &nbsp;
              <Link
                sx={{ color: 'primary' }}
                underline="always"
                href="https://www.app.lawazem.com/privacy-policy"
              >
                {`${translate('Terms_of_Use_and_Privacy_Policy')}`}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
