import * as Yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';

const isValidTel = (value: any) => matchIsValidTel(value);
const RegisterSchema = (translate: any) => Yup.object().shape({
  FullName: Yup.string()
      .required(translate('form_validation.mandatory'))
      .min(3, translate('form_validation.between_03_50_format'))
      .max(50, translate('form_validation.between_03_50_format'))
      .transform((value) => value.trim()),
    CompanyName: Yup.string()
      .required(translate('form_validation.mandatory'))
      .min(3, translate('form_validation.between_03_50_format'))
      .max(50, translate('form_validation.between_03_50_format'))
      .transform((value) => value.trim()),
    PhoneNumber: Yup.string()
      .required(translate('form_validation.mandatory'))
      .min(13, translate('form_validation.the_phone_number_should_contain_9_numbers'))
      .max(13, translate('form_validation.the_phone_number_should_contain_9_numbers'))
      .matches(
        /^(00966|966|\+966)(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{3})([0-9]{4})$/,
        translate('form_validation.valid_saudi_mobile')
      )
      .test('is-valid-tel', translate('form_validation.valid_saudi_mobile'), isValidTel),
      email: Yup.string()
      .required(translate('form_validation.mandatory'))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/
      ,translate('form_validation.valid_email_format'))
      .transform((value) => value.trim()),
  });

  export const  updateMainDataSchema= (translate: any) => Yup.object().shape({
    jobTitle: Yup.string()
      .required(translate('form_validation.mandatory'))
      .min(3, translate('form_validation.between_03_50_format'))
      .max(50, translate('form_validation.between_03_50_format'))
      .transform((value) => value.trim()),
    email: Yup.string()
      .required(translate('form_validation.mandatory'))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/
      ,translate('form_validation.valid_email_format'))
      .transform((value) => value.trim()),
      taxId: Yup.string()
      .required(translate('form_validation.mandatory'))
      .max(15, translate('form_validation.accept_15_characters'))
      .matches(/^[0-9]{0,15}$/, translate('form_validation.please_enter_numbers_only'))
      .transform((value) => value.trim()),
    registrationNumber: Yup.string()
      .required(translate('form_validation.mandatory'))
      .max(15, translate('form_validation.accept_15_characters'))
      .matches(/^[0-9]{0,15}$/, translate('form_validation.please_enter_numbers_only'))
      .transform((value) => value.trim()),
  });

export default RegisterSchema;