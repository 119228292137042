import { SVGProps } from "react"

const LawazemIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={41}
    fill="none"
    {...props}
  >
    <rect width={41} height={41} fill="#4545DB" rx={9} />
    <path
      fill="#fff"
      d="M32.969 14.334c-.331-1.677-1.209-2.79-2.723-3.277a7.823 7.823 0 0 0-2.4-.356H9.953c-.14 0-.285.047-.42.055a1.44 1.44 0 0 0-.96.423c-.257.258-.41.603-.428.969-.028.25-.041.5-.04.752v2.626s.017 2.858.031 3.055c.066.937.546.867 1.398.908 1.097.032 2.194.029 3.29 0 .835-.026 1.29.185 1.403-.718.026-.21.035-1.157.035-1.362v-2.603h2.571c.723 0 .857.116.891.819v3.031c0 .992.529.801 1.429.833a48.49 48.49 0 0 0 2.971 0c.794-.026 1.266.092 1.394-.718.04-.268.052-1.411.052-1.411v-2.54h2.637a.78.78 0 0 1 .556.24.798.798 0 0 1 .226.567l.049 3.017c0 .966.54.807 1.428.833 1.086.032 2.175.026 3.263 0 .84-.023 1.286.119 1.429-.792.025-.17.022-.975.022-1.157v-1.065c.008-.71-.055-1.418-.188-2.114"
    />
    <path
      fill="#E97706"
      d="M12.789 22.925a55.18 55.18 0 0 0-3.278 0c-.853.03-1.326.613-1.391 1.57a70.83 70.83 0 0 0 0 2.909c.065.956.54 1.543 1.391 1.573a57.76 57.76 0 0 0 3.278 0c.83-.027 1.283-.55 1.396-1.476.026-.212.026-2.897 0-3.11-.114-.923-.569-1.446-1.396-1.475"
    />
    <path
      fill="#FDC605"
      d="M22.201 22.925a49.747 49.747 0 0 0-3.112 0c-.93.03-1.472.667-1.496 1.679v2.694c0 1.009.557 1.646 1.496 1.679 1.036.032 2.077.03 3.112 0 .832-.027 1.326-.52 1.46-1.346.042-.271.042-3.092 0-3.364-.134-.826-.628-1.319-1.46-1.342Z"
    />
    <path
      fill="#69C0B4"
      d="M31.743 22.924a56.419 56.419 0 0 0-3.242 0c-.869.026-1.4.664-1.42 1.647v2.758c0 .983.537 1.62 1.42 1.65 1.079.03 2.16.027 3.242 0 .834-.024 1.277-.523 1.419-1.453.026-.174.026-2.979 0-3.153-.136-.93-.568-1.429-1.42-1.45Z"
    />
  </svg>
)
export default LawazemIcon
