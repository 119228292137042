import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  Link,
  Typography,
  Autocomplete,
  InputAdornment,
  Stack,
  CircularProgress,
} from '@mui/material';
import { CustomTextField } from 'src/components/custom-input copy';
import Iconify from 'src/components/iconify/Iconify';
import Image from 'src/components/image/Image';
import { isArray } from 'lodash';
import { replaceArabicWithEnglishNumerals } from 'src/utils/mappingNumbers';
import useResponsive from 'src/hooks/useResponsive';
import { useState } from 'react';

export default function ShopProductSearch({
  searchName,
  setSearchName,
  products,
  translate,
  width,
  autoHighlight,
  redirectionURL,
  setNoProductsMessage = () => {},
  placeholder,
  bgColor,
  setPageNumber = () => {},
  loading,
  withoutBorder,
}: any) {
  const isDesktop = useResponsive('up', 'lg');
  const [queryTxt, setQueryTxt] = useState('');
  const minQueryChar = 3;

  const handleChangeSearch = async (value: any) => {
    try {
      if (!value) {
        setSearchName(null);
        setQueryTxt('');
        setNoProductsMessage(translate('product_not_available'));
      }
      if (value?.trim() !== '') {
        if (value.length >= minQueryChar) {
          setSearchName(value.trim());
        }
        setQueryTxt(value.trim());
        setNoProductsMessage(translate('products_no_result'));
      }
      setPageNumber(1);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGotoProduct = (name: string) => {
    redirectionURL(encodeURIComponent(name));
  };
  const isOptionsHasValue = (product: any) => Object.prototype.hasOwnProperty.call(product, 'name');

  const searchOptions = isArray(products) && queryTxt?.length >= minQueryChar ? products : [{}];

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !loading &&
      searchName?.trim() !== '' &&
      searchName?.trim().length >= minQueryChar &&
      event.key === 'Enter' &&
      searchOptions.length > 0 &&
      isOptionsHasValue(searchOptions[0])
    ) {
      if (searchOptions.length === 1) {
        handleGotoProduct(searchOptions[0].sku);
      } else {
        handleGotoProduct(searchName);
      }
    }
  };

  const handleChange = (value: any, event: any) => {
    if (event.key === 'Enter') {
      handleKeyUp(event);
    } else {
      const updatedValue = replaceArabicWithEnglishNumerals(value);
      setTimeout(() => {
        handleChangeSearch(updatedValue);
      }, 0);
    }
  };
  const setValueWithCurrent = (params: any) => {
    if (params.inputProps.value >= minQueryChar) params.inputProps.value = searchName;
  };

  return (
    <Autocomplete
      freeSolo
      size="small"
      autoHighlight={autoHighlight}
      popupIcon={null}
      options={loading ? [{}] : searchOptions}
      filterOptions={() => (!loading ? searchOptions : [])}
      onInputChange={(event, value) => handleChange(value, event)}
      getOptionLabel={(option) => (option.name ? option.name : option)}
      isOptionEqualToValue={(option, value) => isOptionsHasValue(option) && option.id === value.id}
      componentsProps={{
        popper: {
          sx: {
            width: 'auto',
            ...(!isDesktop && {
              transform: 'translate3d(8px, 111px, 0px) !important',
            }),
            maxWidth: width || `580px !important`,
            ...(!loading &&
              !isOptionsHasValue(searchOptions[0]) && {
                '.MuiAutocomplete-listbox': {
                  alignItems: 'center',
                  height: '100px',
                  justifyContent: 'center',
                  display: 'flex',
                },
              }),
          },
        },
        paper: {
          sx: {
            ...(!isDesktop && {
              maxWidth: 'calc(100vw - 16px)',
              width: 'calc(100vw - 16px)',
            }),
            '& .MuiAutocomplete-option': {
              px: `8px !important`,
            },
          },
        },
      }}
      renderInput={(params) => (
        <>
          {setValueWithCurrent(params)}
          <CustomTextField
            {...params}
            placeholder={placeholder}
            onKeyUp={handleKeyUp}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: bgColor,
                ...(withoutBorder && { border: 'none' }),
                maxWidth: width || 550,
                '&.MuiInputBase-sizeSmall': { borderRadius: '10px' },
                '& input::placeholder': {
                  color: 'text.disabled',
                  fontSize: '16px',
                  fontWeight: '600',
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ width: '24px', height: '24px', ml: 1, color: 'text.primary' }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
      renderOption={(props, product, { inputValue }) => {
        if (isOptionsHasValue(product)) {
          const { name, imageUrl, sku } = product;
          const matches = match(name, inputValue);
          const parts = parse(name, matches);

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li {...props} onClick={() => handleGotoProduct(sku)}>
              <Image
                alt={imageUrl}
                src={imageUrl}
                sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
              />

              <Stack>
                <Link underline="none">
                  {parts.map((part, index) => (
                    <Typography
                      key={index}
                      component="span"
                      variant="subtitle2"
                      color={part.highlight ? 'primary' : 'textPrimary'}
                    >
                      {part.text}
                    </Typography>
                  ))}
                </Link>
                <Typography sx={{ color: '#919EAB', mt: 0 }} variant="body2">
                  {sku}
                </Typography>
              </Stack>
            </li>
          );
        }
        if (!queryTxt || queryTxt.length < minQueryChar)
          return !loading && <li>{translate('keep_trying')}</li>;
        return !loading && <li>{translate('products_no_result')}</li>;
      }}
    />
  );
}
