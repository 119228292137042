// theme
import palette from '../../theme/palette';
import Old_palette from '../../theme/palette_purple';
//
import { ThemeColorPresetsValue } from './types';

// ----------------------------------------------------------------------
const themePalette = palette('light');

export const presets = [
  // DEFAULT
  {
    name: 'default',
    ...themePalette.primary,
  },
  // new Identity
  { name: 'old',...Old_palette('light').primary}
];

export const defaultPreset = presets[0];
export const newPreset = presets[1];

export const presetsOption = presets.map((color) => ({
  name: color.name,
  value: color.main,
}));

export function getPresets(key: ThemeColorPresetsValue) {
  return {
    default: defaultPreset,
    old: newPreset,
  }[key];
}
