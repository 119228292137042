import { setSession, isValidToken } from 'src/auth/utils';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { logSentryError } from 'src/sentry-logger';
import { axiosAuthInstance, axiosInstance, jwtDecode } from './axios';
import { getRedirectUrl } from '../commonFunction';

export const authPaths: any = {
  'Users/send-otp': true,
  'Users/check-otp': true,
  'Users/generate-token-v2': true,
  // 'Company/get-company-type': true,
  'Company/signup-v2': true,
  'Company/active-company-by-otp_v2': true,
  // 'Users/check-main-data-exist': true,
  'Company/get-company-list-user': true,
};

export const TokenService = {
  // Access Token
  updateLocalAccessToken: (accessToken: string) => localStorage.setItem('accessToken', accessToken),
  removeLocalAccessToken: () => localStorage.removeItem('accessToken'),

  get getLocalAccessToken() {
    return localStorage.getItem('accessToken');
  },

  // Refresh Token
  updateLocalRefreshToken: (refreshToken: string) =>
    localStorage.setItem('refreshToken', refreshToken),

  removeLocalRefreshToken: () => localStorage.removeItem('refreshToken'),

  get getLocalRefreshToken() {
    return localStorage.getItem('refreshToken');
  },

  // Client Token
  updateLocalClientToken: (clientToken: string) => localStorage.setItem('clientToken', clientToken),

  removeLocalClientToken: () => localStorage.removeItem('clientToken'),

  get getLocalClientToken() {
    return localStorage.getItem('clientToken');
  },

  // ReFreshTokenExpiration
  updateReFreshTokenExpiration: (reFreshTokenExpiration: string) => localStorage.setItem('reFreshTokenExpiration', reFreshTokenExpiration),

  removeReFreshTokenExpiration: () => localStorage.removeItem('reFreshTokenExpiration'),
  get getReFreshTokenExpiration() {
    return localStorage.getItem('reFreshTokenExpiration');
  },


  // Expiration For Access Token
  updateExpirationDate: (expiration: any) =>
    localStorage.setItem('expiration', JSON.stringify(expiration)),

  removeExpirationDate: () => localStorage.removeItem('expiration'),

  get getExpirationDate() {
    return JSON.parse(localStorage.getItem('expiration') || '{}');
  },
};

export const getClientToken = async () => {
  const response = await axiosAuthInstance.get('Auth/GetToken', {
    params: {
      clientName: 'lawazem.customer.web',
      clientSecret: 'vBdBn7WW6Ab8XCwf95dFLegKsZtnYTTs',
    },
  });
  TokenService?.updateLocalClientToken(response?.data?.data);
  return response?.data?.data;
};
let isRefreshing = false;
let isRefreshingDateTime = Date.now();
const checkIfUnauthorizedPath = () => {
  const path = window.location.pathname.toLocaleLowerCase();
  return path.indexOf('register') > 0 || path.indexOf('login') > 0 || path.indexOf('otp') > 0;
}
export const getRefreshToken = async () => {
  try {
    if (!TokenService.getLocalRefreshToken || !TokenService.getLocalAccessToken) {
      throw new Error('user has not logged in');
    }
    if (!checkIfUnauthorizedPath()) {
      const currentDateTime = Date.now();
      if (!isRefreshing && (currentDateTime > (isRefreshingDateTime + 100))) {
        isRefreshingDateTime = currentDateTime;
        isRefreshing = true;
        const response = await axiosAuthInstance.post(
          '/Users/refresh-token',
          {
            accessToken: TokenService.getLocalAccessToken,
            refreshToken: TokenService.getLocalRefreshToken,
          }

        );
        if (!response) {
          throw new Error('get token failed');
        }
        isRefreshing = false;

        setLoginData(response.data.data);
        return response.data.data;
      }
    }
    return '';
  } catch (error) {
    const tokens = {
      error,
      'refresh token': TokenService?.getLocalRefreshToken,
      'access token': TokenService?.getLocalAccessToken,
    };
    logSentryError('get new access token failed', tokens);

    const accessToken = localStorage?.getItem('accessToken');
    isRefreshing = false;
    if (!isValidToken(accessToken || '')) {
      setSession(null);
      return setLogout(`${window.location.origin}${PATH_DASHBOARD.root}`);
    }
    return accessToken;

  }
};

// Take Two Dates And Gap in Minutes
export const checkGapBetweenTwoDate = ({ now, then, gap = 5 }: any) => {
  const gapInMS = new Date(now).getTime() - new Date(then).getTime();
  const gapInMinutes = gapInMS / 60000;

  return gapInMinutes < gap;
};

export const setLogout = (afterLogoutUrl: string = '') => {
  if (afterLogoutUrl === '') {
    afterLogoutUrl = getRedirectUrl();
  }
  const tokens = {
    'refresh token': TokenService?.getLocalRefreshToken,
    'access token': TokenService?.getLocalAccessToken,
  };
  window.location.replace(afterLogoutUrl);
  TokenService.removeLocalAccessToken();
  TokenService.removeReFreshTokenExpiration();
  TokenService.removeLocalRefreshToken();
  TokenService.removeExpirationDate();
  logSentryError('before redirection to login screen after logout action', tokens);
};

export const setLoginData = ({ accessToken, refreshToken, reFreshTokenExpiration }: any) => {

  TokenService.updateLocalAccessToken(accessToken);
  TokenService.updateReFreshTokenExpiration(reFreshTokenExpiration);
  TokenService.updateLocalRefreshToken(refreshToken);
  TokenService.updateExpirationDate(new Date());
  TokenService.removeLocalClientToken();
}



export const checkRefreshTokenExpirationValid = () => {
  const refreshTokenExp = TokenService?.getReFreshTokenExpiration;
  const isExpired = refreshTokenExp && new Date() >= new Date(refreshTokenExp || '')
  if (isExpired) {
    const tokens = {
      'refreshTokenExp': refreshTokenExp,
      'refresh token': TokenService?.getLocalRefreshToken,
      'access token': TokenService?.getLocalAccessToken,
    };
    logSentryError('refresh token expired', tokens);
    setSession(null);
    setLogout(`${window.location.origin}${PATH_DASHBOARD.root}`);
  }
  return !isExpired;
};

// const checkAccessTokenExpirationValid = async () => {
//   if (TokenService?.getLocalAccessToken) {
//     const decoded = jwtDecode(`${TokenService?.getLocalAccessToken}`);
//     const currentTime = Date.now();
//     const timeLeft = decoded.exp - (currentTime / 1000);
//     if (timeLeft <= 5) {
//       const { accessToken } = await getRefreshToken();
//       return !!accessToken;
//     }
//     return true;
//   }
//   setSession(null);
//   setLogout(`${window.location.origin}${PATH_AUTH.login}`)
//   return false;
// }


axiosInstance.interceptors.request.use(
  async (config) => {
    if (checkRefreshTokenExpirationValid()) {

      if (!TokenService.getLocalAccessToken && (authPaths[config?.url || ''] || config?.url?.includes('Anonymous/'))) {
        if (!TokenService.getLocalClientToken) {
          await getClientToken();
        }
        config.headers.Authorization =
          !!TokenService.getLocalClientToken && `Bearer ${TokenService.getLocalClientToken}`;
      } else {
        config.headers.Authorization =
          !!TokenService.getLocalAccessToken && `Bearer ${TokenService.getLocalAccessToken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosAuthInstance.interceptors.request.use(
  async (config) => {
    if (!TokenService.getLocalClientToken && config.url !== 'Auth/GetToken') {
      await getClientToken();
    }
    config.headers.Authorization =
      TokenService.getLocalClientToken && `Bearer ${TokenService.getLocalClientToken}`;
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
