import { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CallAPI, CallMutation } from 'src/utils/API/APIConfig';
import { GET_USER_NOTIFICATIONS, GET_UNREAD_COUNT, Set_As_Seen_List } from 'src/utils/API/EndPoints/HomeEp';

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [PageSize, setPageSize] = useState(20);
  const [canUpdatePageNumber, setCanUpdatePageNumber] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePageNumber = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };
  const { refetch: refetchCNotifications } =
    CallAPI({
      name: ['getNotifications', pageNumber],
      url: GET_USER_NOTIFICATIONS,
      refetchOnWindowFocus: true,
      enabled: canUpdatePageNumber,
      query: {
        PageSize,
        PageNumber: pageNumber,
      },
      select: (res) => res?.data?.data,
      onSuccess: (res) => {
        if (res.length < PageSize) setCanUpdatePageNumber(prevPageNumber => false);
        setNotifications([...notifications, ...res]);
      },
    });
  const { refetch: refetchNotificationCount } =
    CallAPI({
      name: 'GetUnreadCount',
      url: GET_UNREAD_COUNT,
      refetchOnWindowFocus: true,
      enabled: true,
      select: (res) => res?.data?.data,
      onSuccess: (res) => {
        setUnreadCount(res);
      },
    });

  const SetAsSeenList = CallMutation({
    url: Set_As_Seen_List,
    method: 'post',
    refetchOnWindowFocus: true,
    enabled: true,
    select: (res) => res?.data?.data,
    onSuccess: (res) => {
      refetchNotificationCount();
      setTimeout(() => {
        refetchCNotifications();
      }, 30000);
    },
  });

  const memoizedValue = useMemo(
    () => ({ notifications, refetchCNotifications, unreadCount, SetAsSeenList, pageNumber, updatePageNumber }),
    [notifications, refetchCNotifications, unreadCount, SetAsSeenList, pageNumber, updatePageNumber]
  );
  return (
    <NotificationsContext.Provider value={memoizedValue}>
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NotificationsProvider;
