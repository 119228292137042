// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GlobalWidth } from 'src/config-global';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          fontFamily: 'SomarSans !important',
        },
        html: {
          padding: 0,
          width: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          padding: 0,
          width: '100%',
          height: '100%',
          margin: '0 auto !important',
          maxWidth: `${GlobalWidth.max_w}px`,
          backgroundColor: '#F6F7F8 !important',
          minHeight: '100vh',
        },
        '#root': {
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          minHeight: '100vh',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.MuiBreadcrumbs-root li div': {
          whiteSpace: 'pre-wrap',
        },
        // '.simplebar-scrollbar:before': {
        //   opacity: 1,
        // },
        [`@media screen and (max-width: ${theme.breakpoints.values.lg}px)`]: {
          '.fc-widget-normal': {
            display: 'none',
            '&.fc-open': {
              display: 'block',
            },
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}
