import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import CartWidget from 'src/sections/header/CartWidget';
import SideMenu from 'src/assets/icons/SideMenu';
import { useSettingsContext } from '../../../components/settings';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import Logo from '../../../components/logo';
import { HEADER, NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import useOffSetTop from '../../../hooks/useOffSetTop';
import { bgBlur } from '../../../utils/cssStyles';
import SearchBar from '../../../sections/header/search/SearchInput';
import NotificationsPopover from './NotificationsPopover';
import FinancialPopover from './FinancialPopover';
import DownlodeApp from './DownlodeApp';

type Props = {
  onOpenNav?: VoidFunction;
  shouldShowContent?: boolean
  showHomeContent?: boolean;
};

export default function Header({ onOpenNav, shouldShowContent, showHomeContent }: Props) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const bodyWidth =
    document?.getElementsByTagName('body')[0]?.getBoundingClientRect()?.width || '100%';

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {isDesktop && (
        <div style={{ width: '100%', }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack sx={{ width: '100%', maxWidth: '815px', mr: '10px' }}>
              <SearchBar />
            </Stack>

            <Stack
              flexShrink={0}
              direction="row"
              alignItems="end"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1 }}
              gap="0 10px"
            >
              <CartWidget />
              <NotificationsPopover />
              <FinancialPopover />
              <LanguagePopover />
              <AccountPopover />
            </Stack>
          </Box>
        </div>
      )}

      {!isDesktop && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <DownlodeApp />
          {shouldShowContent && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '80%',
                flexDirection: 'row-reverse',
                width: '100%',
                alignItems: 'center',
                px: { xs: 2, sm: 3 },
              }}
            >
              {showHomeContent && <Stack
                spacing={{ xs: 0.5, sm: 1 }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-start',
                  alignItems: 'start',
                  justifyContent: 'start',
                  pl: '16px',
                }}
              >
                <NotificationsPopover />
              </Stack>
              }

              <Stack sx={{ width: '100%' }}>
                <SearchBar />
              </Stack>
              {showHomeContent && <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pr: '16px',
                }}
              >
                <IconButton onClick={onOpenNav} sx={{ color: 'text.primary', padding: '0' }}>
                  <SideMenu />
                </IconButton>
              </Stack>
              }
            </Box>
          )}
        </Box>
      )}


    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: shouldShowContent ? HEADER.H_MOBILE : 72,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          paddingLeft: `calc(((100% - ${bodyWidth}px ) / 2 ) + ${NAV.W_DASHBOARD - 1}px)`,
          paddingRight: `calc((100% - ${bodyWidth}px ) / 2 )`,
          backgroundColor: 'transparent',
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          background: 'white',
          ...(!isDesktop && {
            px: '0px !important'
          }),
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
