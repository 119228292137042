
const ChatWithUs = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <circle cx={11} cy={11} r={11} fill="#ADBFC4" />
    <g clipPath="url(#a)">
      <path
        fill="#003648"
        fillRule="evenodd"
        d="M14.703 13.094c-.25-.125-1.484-.732-1.714-.816-.23-.084-.397-.126-.565.126-.167.25-.648.816-.794.983-.146.168-.293.189-.544.063-.25-.125-1.059-.39-2.017-1.245-.746-.665-1.25-1.487-1.396-1.738-.146-.251-.016-.387.11-.512.113-.113.251-.293.376-.44.126-.146.168-.251.251-.418.084-.168.042-.314-.02-.44-.063-.125-.565-1.36-.774-1.863-.204-.489-.41-.423-.565-.43a10.092 10.092 0 0 0-.48-.01.922.922 0 0 0-.67.315c-.23.25-.878.858-.878 2.093 0 1.235.9 2.428 1.025 2.595.125.168 1.769 2.702 4.286 3.788.598.259 1.066.413 1.43.529.601.19 1.148.164 1.58.1.482-.073 1.485-.607 1.694-1.193.209-.587.209-1.089.146-1.194-.063-.104-.23-.167-.48-.293Zm-4.577 6.25h-.004a8.333 8.333 0 0 1-4.248-1.163l-.304-.18-3.16.828.844-3.08-.198-.315a8.326 8.326 0 0 1-1.276-4.442c.001-4.601 3.745-8.345 8.349-8.345 2.229 0 4.324.87 5.9 2.448a8.295 8.295 0 0 1 2.442 5.904c-.002 4.602-3.745 8.346-8.345 8.346Zm7.102-15.448A9.976 9.976 0 0 0 10.125.95C4.591.951.086 5.455.085 10.991c-.002 1.77.46 3.498 1.34 5.02L0 21.216l5.323-1.396c1.467.8 3.118 1.221 4.798 1.222h.005c5.534 0 10.038-4.505 10.04-10.041a9.98 9.98 0 0 0-2.938-7.104Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 1h20.167v20.167H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default ChatWithUs
