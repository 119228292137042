import { useState } from 'react';
import { Outlet,useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import GuestPopup from 'src/pages/auth/login/GuestLogin';
import Iconify from 'src/components/iconify';
import useResponsive from '../../hooks/useResponsive';
import { useSettingsContext } from '../../components/settings';
import Main from './Main';
import GuestHeader from './header/GuestHeader';
import NavMini from './NavGuest/GuestNavMini';
import GuestNavVertical from './NavGuest/GuestNavVertical';
import NavHorizontal from './NavGuest/GuestNavHorizontal';

// ----------------------------------------------------------------------

export default function 
GuestLayout() {
  const { themeLayout } = useSettingsContext();
  const [openGuestPopup, setOpenGuestPopup] = useState(false);

  const location = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenGuestPopup = async () => {
    setOpenGuestPopup(true);
  };

  const renderNavVertical = <GuestNavVertical openNav={open} onCloseNav={handleClose} />;
  
  localStorage.removeItem("redirectUrl");

  if (isNavHorizontal) {
    return (
      <>
        <GuestHeader onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <GuestHeader onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }
   // Function to determine if we should hide content based on the path
   const shownPaths = ['/categories/', '/search'];
   const path = location.pathname;
   const shouldShowContent = !!shownPaths.find((keyword) => path === '/' || path.includes(keyword));
   const showHomeContent = !!shownPaths.find((keyword) => path === '/');
 
  return (

    <Box
      sx={{

        position: 'relative',
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        '& *:focus-visible ': {
          outline: 'none;'
        }
      }}
    >
      <>
        <GuestHeader onOpenNav={handleOpen}  shouldShowContent={shouldShowContent} showHomeContent={showHomeContent} />
        {renderNavVertical}
      </>

      <Main shouldShowContent={shouldShowContent}>
        <Outlet />
      </Main>
      {!isDesktop && shouldShowContent && (
        <Box
        onClick={handleOpenGuestPopup}
        sx={{
            height: '52px',
            width: '52px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            position: 'fixed',
            bottom: '50px',
            right: '40px',
            zIndex: '1000',
          }}
        >
            <Iconify icon="mdi:cart-outline" color="white" width={34} />
        </Box>
      )}
       {openGuestPopup &&
          <GuestPopup open={openGuestPopup} onClose={() => setOpenGuestPopup(false)} />
         }
    </Box>
  );
}
