import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@mui/material';
import { GuestCategoriesContext } from 'src/utils/Contexts/Categories/GuestCategoriesProvider';
import { ANONYMOUS_PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import packIcon from 'src/assets/icons/packs.png';
import useActiveLink from '../../../../hooks/useActiveLink';
import { NavListProps } from '../../types';
import GuestNavItem from './GuestNavItem';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  isCategoryItem?: boolean;
  isRfqItem?: boolean;
};

export default function GuestNavList({ data, depth, hasChild, isCategoryItem, isRfqItem }: NavListRootProps) {
  const { pathname } = useLocation();
  const { active, isExternalLink } = useActiveLink(data.path,data.deep);
  const [open, setOpen] = useState(active);
  const { CategoriesPath } = useContext(GuestCategoriesContext);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!isRfqItem &&
         <GuestNavItem
          item={data}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={handleToggle}
        />
      }

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
      {isCategoryItem && (
        <Collapse in={open} unmountOnExit>
          <CategorySubList data={CategoriesPath} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <GuestNavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}

type NavCatListSubProps = {
  data: NavListProps[];
  depth: number;
};

function CategorySubList({ data, depth }: NavCatListSubProps) {
  const { translate } = useLocales();
  const essentialsPack = {
    id: '-1',
    title: `${translate('starter_pack')}`,
    imageUrl: packIcon,
    path: `${ANONYMOUS_PATH_DASHBOARD.categories.EssentialsPack}`
  }
  return (
    <>
      <GuestNavList
        key='essentialsPack'
        data={essentialsPack}
        depth={depth + 1}
        hasChild={false}
      />
      {data?.map((list) => (
        <GuestNavList
          key={`cat${list?.name}${list?.id}`}
          data={list}
          depth={depth + 1}
          hasChild={false}
        />
      ))}
    </>
  );
}
