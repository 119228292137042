import { useState, useContext } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Button } from '@mui/material';
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';
import { setLogout } from 'src/utils/API/Helpers';
import avatarSrc from 'src/assets/shared/avatar.png';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import ConfirmDialog from '../../../components/confirm-dialog';
import { useLocales } from '../../../locales';

export default function AccountPopover() {
  const { username, UserInfo } = useContext(UserContext);
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      setLogout();
      handleClosePopover();
      // @ts-ignore:next-line
      if (window?.productFruits?.services) {
        // @ts-ignore:next-line
        window.productFruits.services.destroy();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Box
          component="img"
          src={avatarSrc}
          sx={{ borderRadius: '50%', width: 30, height: 30, cursor: 'pointer' }}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {UserInfo?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
          }}
          sx={{ m: 1 }}
        >
          {`${translate('logout')}`}
        </MenuItem>
      </MenuPopover>
      <ConfirmDialog
        fullWidth
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('logout')}`}
        content={<>{`${translate('logout_message')}`}</>}
        action={
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              handleLogout();
              handleCloseConfirm();
            }}
          >
            {`${translate('logout')}`}
          </Button>
        }
      />
    </>
  );
}
