const en = {
  lawazem: 'Lawazem',
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  Add_new_company: 'Add new company',
  Add_company: 'Add company',
  Help: 'Help',
  UserProfile: 'User Profile',
  file_download: ' File Download',
  delivery_note: 'Delivery note',
  renew_quotation: 'Renew Quotation',
  Account: 'Account',
  UserDetails: 'User Details',
  Firstname: 'First name',
  Lastname: 'Last name',
  job_title: 'Job title',
  Reports: 'Reports',
  ReorderingLists: 'Reordering List',
  Deactivated_successfully: 'Deactivated successfully',
  Deactivate: 'Deactivate',
  delete_Tag_confirm_message: 'Are you sure you want to deactivate this tag?',
  Download_PDF: ' Download PDF',
  management: 'Management',
  invoices: 'Invoices',
  RFQs: 'RFQs',
  Order: 'Order',
  Categories: 'Categories',
  Marketplace: 'Marketplace',
  home: 'Home',
  CustomerService: 'Customer Service',
  CompanyProfile: 'Company Profile',
  CompaniesList: 'Companies List',
  switch_company: 'Switch Company',
  CompanyDetails: 'Company Details',
  companyname: 'Company name',
  VatID: 'Vat ID',
  Commercialregistrationnumber: 'Commercial registration number',
  Catering: 'Catering',
  OfficeFurniture: 'Office Furniture',
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `Edit`,
  shop: `shop`,
  privacy_policy: 'Privacy Policy',
  terms_service: 'Terms of Service',
  signup_agree_to: 'By signing up, I agree to',
  and: 'and',
  auth: {
    slogan: 'Order all products and services easily and at the best price',
    sign_in_to_lawazem: 'Sign in to LAWAZEM',
    sign_in: 'Sign in',
    login: 'Login',
    new_user: 'New user?',
    Orders: 'Orders',
  },
  otp: {
    mobile_verification: 'Mobile Verification',
    otp_message: 'Please enter the code sent to your mobile phone',
    verify: 'Verify',
    dont_have_code: 'Don’t have a code?',
    resend_code: 'Resend code',
    return_to_sign_in: ' Return to sign in',
    return_to_sign_Up: ' Return to sign up',
    resend_otp_success_message: 'OTP is sent successfully',
    message: {
      please_enter_valid_OTP_number: 'Please enter valid OTP number',
      account_is_created_successfully: 'Account is created successfully',
      login_successful: 'Login Successful',
    },
  },
  signup: {
    start_free: 'Get started absolutely free.',
    have_account: 'Already have an account?',
    create_account: 'Create account',
  },
  form: {
    first_name: 'First name',
    last_name: 'Last name',
    job_title: 'Job title',
    email_address: 'Email address',
    mobile_number: 'Mobile number',
    company_name: 'Company name',
    vat_id: 'VAT ID',
    commercial_registration: 'Commercial registration',
    optional: 'optional',
    name: 'Name',
    description: 'Description',
    save: 'Save',
    cancel: 'Cancel',
    name_validation_unique: 'Name should be unique',
  },
  form_validation: {
    mandatory: 'Mandatory',
    address_mandatory: 'Address is mandatory',
    valid_email_format: 'Please add valid email format',
    between_03_50_format: 'Should be between 3 and 50 characters',
    max_50_format: 'Max 50 characters',
    max_100_format: 'Max 100 characters',
    max_200_format: 'Max 200 characters',
    max_500_format: 'Max 500 characters',
    valid_saudi_mobile: 'Please add a valid Saudi mobile number',
    accept_15_characters: 'Accept maximum 15 characters',
    email_already_exists: 'The email already exists before',
    phone_already_exists: 'The phone number already exists before',
    the_phone_number_should_contain_9_numbers: 'The phone number should contain 9 numbers.',
    please_enter_numbers_only: 'Please enter numbers only',
    address_within_saudi_arabia: 'Please choose a delivery address within Saudi Arabia',
    payment_option_required: 'Payment option is required',
    allowed_files_types: 'The allowed files types are  word, PDF & image',
    allowed_files_size: 'The allowed size is 500 KB',
    allowed_pdf_only: 'Allow to upload PDF file only',
    allowed_files_size_10: 'Allow to upload PDF file with maximum size 10 Mega',
    rfq_submit_validation:
      'To submit, you must include either RFQ content description or product line.',
    end_date_later_today: 'The end date should be equal to or later than the today date',
    price_validation: 'Max limit has been exceeded',
    amount_min_0: 'Value should be bigger than 0',
  },
  sar: 'SAR',
  product_filter_name: 'Name',
  product_filter_new: 'Newest first',
  product_filter_old: 'Oldest first',
  product_filter_price_high: 'Price ( Highest price first )',
  product_filter_price_low: 'Price ( Lowest price first )',
  product_arrange: 'Sort By',
  Select_your_company: 'Select your company',
  product_not_available: 'No products available',
  price_min: 'min price',
  price_max: 'max price',
  min_price_amount_validation: 'The amount should be bigger than the min price',
  max_price_amount_validation: 'The amount should be less than the max price',
  filters: 'Filters',
  price: 'Price',
  clear: 'Clear',
  clear_all: 'Clear all',
  select: 'Select',
  logout: 'Logout',
  logout_message: 'Are you sure you want to logout?',
  cancel: 'Cancel',
  products_general_search_placeholder: 'Search for a products',
  products_search_placeholder: 'Search within results...',
  products_no_result: 'There are no products that match your selected criteria.',
  search: 'Search',
  search_results_for: 'Search results for',
  filter_submit: 'Filter',
  submit: 'submit',
  upload: 'upload',
  vat: 'VAT',
  quantity: 'Quantity',
  description: 'Description',
  add_to_cart: 'Add to cart',
  add_to_list: 'Add to list',
  new_list: 'New list',
  create_new_list: 'Create new list',
  uptate_reorder_list: 'Edit list',
  created_successfully: 'Created successfully',
  updated_successfully: 'Updated successfully',
  please_select_quantity_before_adding: 'Please select a quantity before adding to cart.',
  reorder_list_column: 'List',
  number_of_products: '# of Products',
  reorder: 'Reorder',
  delete: 'Delete',
  delete_list: 'Delete',
  duplicate_list: 'Duplicate',
  edit_list: 'Edit',
  created_at: 'Created at',
  no_lists_to_display: 'There are no lists to display',
  added_successfully: 'Added successfully',
  product_not_available_at_category: 'There is no product available at this category',
  add_new_list: 'Add new list',
  deleted_successfully: 'Deleted successfully',
  delete_list_confirm_message: 'Are you sure you want to delete this list?',
  delete_product_confirm_message: 'Are you sure you want to delete this product?',
  delete_all_cart_confirm_message:
    'Are you sure you want to clear all the product(s) from the cart?',
  no: 'No',
  product: 'Product',
  fixed_list_tooltip_msg: "You can't modify this list that created by Lawazem",
  copy_of: 'Copy of',
  cart: 'Cart',
  checkout: 'Checkout',
  delivery_address: 'Delivery address',
  payment: 'Payment',
  cart_is_empty: 'Your Cart is Empty',
  empty_cart_description:
    "Looks like you haven't added anything to your cart yet. Continue shopping",
  empty_rfq_table_description:
    "Looks like you haven't added anything yet, Search for products to add them",
  continue_shopping: 'Continue Shopping',
  order_summary: 'Order summary',
  subtotal: 'Subtotal',
  subtotal_vat: 'VAT',
  exp: 'Exp',
  total: 'Total',
  total_price: 'Total price',
  total_price_mobile: 'Total',
  checkout_btn_text: 'Check Out',
  vat_included: 'Total VAT',
  list_doesnt_contain_product: "List doesn't contain any products",
  selected: 'Selected',
  duplicate_list_title: 'Duplicate list',
  product_item: 'Item',
  clear_cart: 'Clear cart',
  delete_product_title: 'Delete Product',
  delete_cart_title: 'Delete Products',
  pdf_quotation: 'PDF quotation',
  cart_terms_msg:
    'By completing the checkout, I consent to award Lawazem LLC an order to execute and deliver',
  updated_price_message:
    'Prices updated! Review the revised prices below before proceeding with your order',
  deliver_to_this_address: 'Deliver to this Address',
  add_new_address: ' Add new address',
  back: 'Back',
  message_confirm_switch_company:
    'Are you sure you want to change the current selected company and switch to',
  change_company: 'Change company',
  search_for_location: 'Search for location ',
  address: 'Address',
  address_name: 'Address name',
  locate_me: 'Locate Me',
  your_location: 'Your location',
  Shipping_fees: 'Shipping fees',
  pending: 'Pending',
  confirmed: 'Confirmed',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
  order_number: 'Order Number',
  By_signing_up_i_agree_to: 'By signing up, I agree to',
  Terms_of_Use_and_Privacy_Policy: ' Terms of Use and Privacy Policy.',
  Order_date: 'Order Date',
  RequestForQuotation: 'Request For Quotation',
  rfq: 'RFQ',
  Amount: 'Amount',
  order_status: 'Order Status',
  Payment: 'Payment',
  paid: 'Paid',
  notpaid: 'Not paid',
  paid_invoice: 'Paid Invoices',
  notpaid_invoice: 'Unpaid Invoices',
  All: 'All',
  End_date_error_msg: 'The end date should be equal to or later than the start date',
  Start_date: 'Start date',
  End_date: 'End date',
  Search_by_order_number: 'Search order number',
  Search_by_invoice_number: 'Search by invoice number',
  invalid_date: 'Invalid date',
  future_date_not_allowed: "Future date isn't allowed",
  orders_no_result: 'There are no orders that match your selected criteria.',
  invoice_no_result: 'There are no invoice that match your selected criteria.',
  shipping_fees: 'Shipping fees',
  building: 'Building',
  contact_person_name: 'Contact person name',
  credit_debit_card: 'Credit/Debit Card',
  support_paymaent_cards_msg: 'We support Mastercard, Visa, and Mada',
  you_can_use_cards: 'You can use Mada, VISA, or Mastercard',
  you_can_casr_or_card: 'You can pay the courier in cash or card',
  cash_on_delivery: 'Cash on delivery',
  pay_with_cash: 'Pay with cash or card when your order is delivered',
  agreement: 'Credit account - Framework agreement',
  credit_account: 'Credit Account',
  credit_account_description: 'Select your signed agreement from the list',
  payment_options: 'Payment options',
  payment_methods: 'Payment methods',
  complete_order: 'Complete order',
  add_new_card: 'Add new card',
  sale_order: 'Sale order',
  thank_you_for_order: 'Thank you for your order!',
  thank_you_for_placing_order:
    'Thanks for placing your order, We will send you a notification when it ships. If you have any question or queries then don’t hesitate to contact us',
  go_to_orders: 'Go to orders',
  order_status_not_valid:
    'Something went wrong while processing the payment request please try again or select another payment method',
  order_status_valid:
    'Order submitted successfully and your payment is in progress, please check later after 5 minutes',
  address_not_available: 'No address available',
  try_again: 'Try again',
  select_another_payment_method: 'Select another payment method',
  update_payment: 'Update payment',
  update_payment_mobile: 'Update payment method',
  payment_updated_successfully: 'Payment method is updated successfully',
  order_created_successfully: 'Order created successfully',
  free: 'Free',
  due_balance: 'Due Balance:',
  print_quotation: 'Print Quotation',
  question_mark: '?',
  delivery_addresses: 'Delivery addresses',
  address_details: 'Address Details',
  no_orders_yet: 'No orders yet!',
  no_invoices_yet: 'There is no invoice available for this order',
  payment_status: 'Payment Status',
  payment_method: 'Payment Method',
  by_card: 'By Card',
  cash: 'Cash',
  view_order_details: 'You can view your order details',
  here: 'here',
  close: 'Close',
  agreement_txt: 'Agreement',
  Vat_short_text: 'VAT',
  not_allowed_before_1900: 'Not allowed to select date before 1900',
  product_details: 'Product Details',
  related_invoices: 'Related Invoices',
  payment_information: 'Payment information',
  paid_amount: 'Paid amount',
  slider_img_1_title: 'Order your business purchases',
  slider_img_1_description: 'with ease and the best price',
  billing_details: 'Billing Details',
  new_card: ' New card',
  order_agian: 'Order Again',
  delete_card_confirm_message: 'Are you sure you want to delete this card?',
  PrivacyPolicy: 'Privacy Policy',
  please_fill_missing_data: 'Please Fill missing data',
  full_name: 'Full name',
  edit_address: 'Edit new address',
  delete_address_confirm_msg: 'Are you sure you want to delete this address?',
  pack_type: 'Pack Type',
  invoice_number: 'Invoice number',
  invoice_date: 'Invoice date',
  due_date: 'Due date',
  select_payment_card: 'Select a card',
  select_address: 'Select address',
  no_payment_cards: 'No payment cards',
  no_address_options: 'No addresses available',
  no_options: 'No options available',
  no_select_options: 'No options available',
  nvalid_card_holder: 'Card holder is required',
  products_will_deleted_alert:
    'Attention! The following products are not available yet, and will be removed from the cart',
  products_packtype_updated_alert:
    'Attention! The pack type of the following products have been updated Please review it before proceeding with your order',
  products_not_avaliable_alert:
    'Please be aware that it is currently not available to reorder the following products',
  products_price_changed_alert: 'And the following products prices had been changed',
  please_aware_products_price_changed_alert:
    'Please be aware that the following products prices had been changed',
  purchase_Order: 'Purchase Order',
  didnot_find_what_you_want: 'Didn’t find what you are looking for?',
  provide_description:
    'Provide description of each product and we shall do our best to source it for you.',
  enter_email_quotation: 'Enter the email where  quotation will be sent',
  tell_us_looking_for: 'Tell us what you are looking for?',
  accept_max_500: 'Accept max 500 characters',
  sorry: 'Sorry !',
  product_not_found: 'The Product isn’t available now ',
  go_to_marketplace: 'Go To Marketplace',
  ok: 'Ok',
  credit_account_purchase_order: 'Credit Account - Purchase Order',
  credit_account_purchase_order_desc:
    'Use your approved credit account by uploading a Purchase Order',
  requestForQuotation: 'Request for quotation',
  new_request: 'New request',
  add_attachment: 'Add attachment',
  submit_request: 'Submit request',
  Coupon_discount: 'Coupon discount',
  Coupon: 'Coupon',
  Message: 'Message',
  Coupon_code: 'Coupon code',
  apply: 'Apply',
  quotation: 'Quotation',
  in_progress: 'In progress',
  convert_to_order: ' Convert to order',
  RFQ_Content: 'RFQ Content',
  Attachment: 'Attachment',
  quotation_date: 'Quotation Date:',
  expired: 'Expired',
  rfq_number: 'RFQ Number',
  Go_to_marketplace: 'Go to marketplace',
  access_denied: 'Access Denied',
  You_do_not_have_permission_to_view_this_page: 'You do not have permission to view this page',
  no_rfq_yet: 'No Requests yet',
  rfq_no_result: 'No result',
  request_date: 'Request Date',
  expiry_date: 'Expiry Date',
  status: 'Status',
  add_attachments: 'Add attachments',
  no_order_lines_to_display: 'There are no order lines to display',
  order_lines: 'Order Lines',
  Submitted: 'Submitted',
  order: 'Order',
  discussion: 'Discussion',
  type_a_message: 'Type a message',
  message: 'Message',
  accepted_files_hint: 'Accepted attachments',
  files_extenation: '[.pdf, .jpeg, .png, .doc, .docx]',
  request_submitted_successfully: 'Request submitted successfully',
  something_went_wrong_with_your_selected_company:
    'Something went wrong with your selected company, please select another company',
  something_went_wrong_with_your_account:
    'Something went wrong with your account, please contact Lawazem customer services team',
  discount: 'Discount',
  rfq_no_access_message:
    "Sorry!, You can't submit your order since your company doesn't have the capability to generate RFQs.",
  credit_not_processing: 'Your Company’s Credit Balance Cannot Process This Order',
  contract_remaining_balance: 'Your company’s contract remaining balance cannot process this order',
  Select_payment_method: 'Select payment method',
  credit_limit: 'Credit Limit',
  remaining_balance: 'Remaining Balance',
  remaining: 'Remaining',
  unpaid_invoices: 'Unpaid Invoices',
  for_more_information: 'For More Information Please Contact Sales Department At',
  products_pack_type_changed_alert:
    "The following products' pack type has been changed, please review it before ordering",
  notifications: 'Notifications',
  view_all: 'View All',
  Market: 'Market',
  Express: 'Express',
  no_notifications_yet: 'No Notifications Yet',
  you_have_no_notifications: 'You have no notifications right now come back later',
  Enter_promo_code_here: 'Enter promo code here',
  invoices_s: 'Invoices',
  invoice: 'Invoice',
  mobile_redirection_message:
    'For the best experience, our website supports large screen devices only',
  download_our_app: 'Go and download our App',
  starter_pack: 'Office Essentials Packs',
  sign_up: 'Sign up',
  filter_by_categories: 'Filter by categories',
  filter_by: 'Filter by',
  Pay_Invoice: 'Pay Invoice',
  due_amount: 'Due Amount',
  invoices_no_result: 'invoices no result',
  unpaid: 'Unpaid',
  overdue: 'Overdue',
  no_invoices_available_yet: 'No invoices available yet',
  partially_paid: 'Partially Paid',
  date_type: 'Date type',
  select_agreement_contract: 'Select agreement',
  no_available_contracts: 'No available contracts',
  No_Available_agreement: 'No Available agreement',
  the_agreement: 'Agreement',
  add_list_of_products: 'Add list of products',
  sign_in: 'Sign in',
  to_get_started: 'to get started',
  Welcome_to_LAWAZEM: 'Welcome to LAWAZEM',
  new_rfq: 'New Request For Quotation',
  new_RFQ: 'New RFQ',
  LoginToGetTheBestExperience: 'Login to get the best experience',
  SpendingsReport: 'Spendings Report',
  ProductInvoicingSummaryReport: 'Product Invoicing Summary Report',
  NoDataFound: 'There is no data to display',
  AnalyticsTags: 'Analytics Tags',
  Tag: 'Tag',
  OrdersCount: 'Orders Count',
  OrdersTotalAmount: 'Orders Total Amount',
  Active: 'Active',
  AddNewTag: 'Add New Tag',
  no_tags_to_display: 'There are no tags to display',
  select_new_tag: 'Select new tag',
  stores: 'Stores',
  Invite_your_Colleague_to: 'Invite your colleague to',
  Invite: 'Invite',
  Send: 'Send',
  Invitaion_content:
    'Easily invite a colleague to join our platform and be assigned to your company by filling in their details below.',
  Invitaion_content2: 'They will receive a notification via SMS with a link to sign in.',
  my_catalog: 'My Catalog',
  InvitationSentSuccessfully: 'The invitation has been sent successfully',
  Express_Fees: 'Express Fees',
  NotActive: 'Not active',
  Add: 'Apply',
  AddTag: 'Add tag',
  financial_info: 'Financial Info',
  your_financial_info: 'Your financial info',
  finances: 'Finances',
  total_unpaid_invoice: 'Total Unpaid invoice',
  oldest_unpaid_invoice: 'Oldest unpaid invoice age',
  credit_limit_company: 'Company credit limit',
  days: 'Days',
  remaining_credit_limit: 'Remaining Credit limit',
  consumed: 'Consumed',
  Donnt_Have_account: 'Don’t have an account?',
  Lawazem_is_better_on_the_app: 'Lawazem is better on the app',
  open_in_the_lawazem_app: 'open in the lawazem app',
  settings: 'Settings',
  manage_company: 'Manage Company',
  add_delivery_address: 'Add Delivery Address',
  orders_history: 'Orders History',
  placed_on: 'Placed On',
  my_companies: 'My Companies',
  invite_colleague: 'Invite colleague',
  my_cart: 'My Cart',
  Proceed: 'Proceed',
  pick_delivery_address: 'Pick Delivery Address',
  enter_note_here: 'Enter Note Here !',
  place_your_order: 'Place you order',
  saved_cards: 'Saved Cards',
  items: 'Items',
  no_items: 'No Items',
  fixed_price_list: 'Fixed price list',
  order_details: 'Order Details',
  payment_details: 'Payment Details',
  create_list: 'Create list',
  select_all: 'Select All',
  update: 'Update',
  UpdateProductQuantity: 'Update Product Quantity',
  product_list: 'Product list',
  tags: 'Tags',
  AddSelectedItemsToCart: 'Add selected items to cart',
  update_list: 'Update list',
  unselect_all: 'Unselect All',
  select_payment_method: 'Select payment method',
  ChatWithSalesTeam: 'Chat with sales team',
  start_chat: 'Start Chat',
  request: 'Request',
  active_orders: 'Active orders',
  show_all: 'Show all',
  latest_products: 'Latest Products',
  select_tag: 'Select tag',
  ViewDetails: 'View Details',
  add_to_reorder_list: 'Add to Re-order list',
  remove_from_all: 'Remove from all',
  remove: 'Remove',
  go_to_reorder_list: 'Go to Re-order List',
  Privacy: 'Privacy',
  about: 'About',
  Profile: 'Profile',
  notes: 'Notes',
  open: 'Open',
  Shop_online_for: 'Shop online for',
  card_ending_with: 'Card ending with',
  of: 'Of',
  you_exceed_the_limit: 'You’ve exceed the limit !',
  subscription_management: 'Subscriptions management',
  subscriptions: 'Subscriptions',
  add_new: 'Add new',
  add_new_subscription: 'Add new subscription',
  next_renew_date: 'Next renewal date',
  subscription_type: 'Subscription type',
  subscription_payment_card: 'Subscription Payment Card',
  card_holder_name: 'Card holder Name',
  valid_thru: ' Valid Thru',
  security_code: 'Security code',
  type: 'Type',
  redirect_cart_message:
    'Your order has been created successfully! Please complete your payment to proceed. Complete Payment',
  go_to_order: 'Go to your order',
  Exp: 'Exp',
  chat_history: 'Chat History',
  Your_chat_history_is_empty: 'Your chat history is empty',
  Renewal: 'Renewal',
  see_more: 'see more',
  see_less: 'see less',
  add: 'Add',
  not_allowed_adding_address:
    'Only company admins can add an address. Please contact your admin to add or update it',
  submit_for_approval: 'Submit for approval',
  approval_request_has_been_sent: 'Approval request has been sent successfully',
  follow_updates: 'Follow updates from the order list',
  selecting_payment_after_approval:
    'This step is for selecting the payment method only , Payment will be completed after approval',
  ChatWithUs: 'Chat with us',
  waiting_approval: 'Waiting approval',
  invalid_order: 'Invalid order',
  unable_proceed_order_msg:
    "We're unable to proceed your order for approval due to the following issues :",
  order_has_been_approved: 'Order has been approved',
  You_can_send_it_back: 'You can send it back to the requester for revisions',
  send_to_requester: 'Send to Requester',
  Rejection_Reason: 'Rejection reason',
  Please_write_your_rejection_reason: 'Please write your rejection reason',
  Confirm: 'Confirm',
  approved: 'Approved',
  Pending_approval: 'Pending approval',
  Approver: 'Approver',
  to_approve: 'Waiting approval',
  rejected: 'Rejected',
  Created_by: 'Created by',
  Current_WF_Approver: 'Current WF Approver',
  Requester: 'Requester',
  keep_trying: 'Keep typing! Please enter more than 2 characters to see product results.',
  mobile_redirect_message:
    'For a better experience and to view detailed reports, please open this page in the web version.',
  Go_to_Home: ' Go to Home',
  Reject: 'Reject',
  Approve: 'Approve',
  myApproval: 'My Approval',
  teamApproval: 'Team Approval',
};

export default en;
