import { alpha } from '@mui/material/styles';
import { blue, purple } from '@mui/material/colors';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'cancelled';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface CommonColors {
    statusColors:any;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#003648'
};

const OFFWHITE = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '##F4F4F4',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};


const PRIMARY = {
  lighter: purple[100],
  light: purple[300],
  main: purple[500],
  dark: purple[700],
  darker: purple[900],
  contrastText: '#FFFFFF',
  lighter10:alpha('#5ECDBB', 0.1),
  lighter20:alpha('#5ECDBB', 0.2),
  lighter30:alpha('#5ECDBB', 0.3),
  lighter100:'#5ECDBB'
};

const GREYCOLOR = {
  lighter: GREY[100],
  light: GREY[300],
  main: GREY[500],
  dark: GREY[700],
  darker: GREY[900],
  moreDarker: GREY[1000],
  contrastText: '#000',
};
const OFFWHITECOLOR = {
  lighter: OFFWHITE[100],
  light: OFFWHITE[300],
  main: OFFWHITE[500],
  dark: OFFWHITE[700],
  darker: OFFWHITE[900],
  contrastText: '#000',
  charcoalGray: '#292F33'
};

const EXPIRED={
  lighter: '#FF964A',
  light: '#FF964A',
  main: '#FF964A',
  dark: '#FF964A',
  darker: '#FF964A',
  contrastText: '#FFFFFF',
}

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#FFC700',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: blue[100],
  light: blue[300],
  main: '#5ECDBB' ,
  dark: blue[700],
  darker: blue[900],
  contrastText:"#FFFF"
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  text:'#E87605',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FF964A',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const STATUSCOLORS={
  success:{
    main:"#36B37E",
    dark:"#5ECDBB",
    light:"#5ECDBB"
  },
  primary:{
    main:"#00B8D9",
    dark: PRIMARY.main,
    light: PRIMARY.main
  },
  warning:{
    main:"#FFAB00",
    dark:"#E87605",
    light:"#E87605"
  },
  secondary:{
    main:"#FFAB00",
    dark:"#FFC700",
    light:"#FFC700"
  },
  darkBlue:{
    main:"#00B8D9",
    dark:"#003648",
    light:"#003648"
  },
  lightBlue:{
    main:"#3366FF",
    dark:"#007EBB",
    light:"#007EBB"
  },
  greyColor :{
    light: GREY[300],
    main: GREY[500],
    dark: GREY[700],
  }
}

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF', deeplavender: '#995A9A', royalorchid: '#9637B0',statusColors:STATUSCOLORS },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  expired: EXPIRED,
  grey: GREY,
  greycolor: GREYCOLOR,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  card_bg: '#F9F9F9',
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    toasterText:{
      primary: '#1B806A',
      error:'#B71D18'
    },
    background: { paper: '#FFFFFF', default: '#FFFFFF', neutral: GREY[200] },
    toasterBackground: { paper: '#D8FBDE', default: '#D8FBDE', neutral: GREY[200], error:'#FFE9D5' },
    action: {
      ...COMMON.action,
      active: GREY[600],
      primary: purple[500],
      primaryActive: purple[800],
      secondary:  '#FFC700'
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
