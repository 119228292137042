import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_AUTH } from 'src/routes/paths';
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, changeCompanyRequired } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }
  
  if (localStorage?.getItem('accessToken') && changeCompanyRequired) {
      return <Navigate to={PATH_AUTH.choose_company} />;
  }

  return <> {children} </>;
}
