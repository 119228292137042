import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, BoxProps } from '@mui/material';
import logo from 'src/assets/shared/logo.png';


export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  redirectUrl?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false,redirectUrl ="/", sx, ...other }, ref) => {
    const logoImg = (
      <Box
        component="img"
        src={logo}
        sx={{ padding: '0 15px', height: 22, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logoImg;
    }

    return (
      <Link component={RouterLink} to={redirectUrl} sx={{ display: 'contents' }}>
        {logoImg}
      </Link>
    );
  }
);

export default Logo;
