import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TokenService, checkGapBetweenTwoDate } from 'src/utils/API/Helpers';
import { CallAPI } from 'src/utils/API/APIConfig';
import { GET_USER_AUTHORIZATION } from 'src/utils/API/EndPoints/HomeEp';

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [User, setUser] = useState({
    access_token: TokenService.getLocalAccessToken,
    refresh_token: TokenService.getLocalRefreshToken,
  });
  const [UserInfo, setUserInfo] = useState({});
  const [currentCumpanyInfo, setCurrentCumpanyInfo] = useState({});
  const [AuthorizationInfo, setAuthorizationInfo] = useState({});

  const setLogin = useCallback(
    ({ accessToken, refreshToken, reFreshTokenExpiration }) => {
      setUser({
        ...User,
        access_token: accessToken,
        refresh_token: refreshToken,
        client_token: null,
      });
      // Update Refresh Token In LocalStorage

      TokenService.updateLocalAccessToken(accessToken);
      TokenService.updateReFreshTokenExpiration(reFreshTokenExpiration);
      TokenService.updateLocalRefreshToken(refreshToken);
      TokenService.updateExpirationDate(new Date());
      TokenService.removeLocalClientToken();
    },
    [User]
  );

  const fillUserProviderFromLocalStorage = useCallback(
    () => {
      setUser({
        ...User,
        access_token: TokenService.getLocalAccessToken,
        refresh_token: TokenService.getLocalRefreshToken,
      });
    },
    [User]
  );


  const isAuthenticated = useCallback(() => !!User.access_token, [User.access_token]);
  const setUserClientToken = useCallback((client_token) => setUser({
    ...User,
    client_token
  }), [User]);

  const {refetch:fetchAuthData}= CallAPI({
    name: 'getAuthData',
    url: GET_USER_AUTHORIZATION,
    enabled: !!isAuthenticated(),
    select: (res) => res.data.data,
    onSuccess: (data) => {
      setAuthorizationInfo(data);
    },
  });
  
  const memoizedValue = useMemo(
    () => ({
      User,
      setUser,
      setLogin,
      isAuthenticated,
      setUsername,
      username,
      UserInfo,
      setUserInfo,
      AuthorizationInfo,
      setCurrentCumpanyInfo,
      currentCumpanyInfo,
      setUserClientToken,
      fillUserProviderFromLocalStorage,
      fetchAuthData,
    }),
    [User, isAuthenticated, setLogin, username, UserInfo, setUserClientToken, currentCumpanyInfo,
       fillUserProviderFromLocalStorage,fetchAuthData, AuthorizationInfo]
  );
  return (
    <UserContext.Provider value={memoizedValue}>
      {React.Children.only(children)}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserProvider;
