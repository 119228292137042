import React, { createContext, useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import { CallMutation, CallAPI } from 'src/utils/API/APIConfig';
import { useSnackbar } from 'src/components/snackbar';
import { PATH_DASHBOARD } from "src/routes/paths";
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';
import { useLocales } from '../../../locales';

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({
    items: [],
    comment: "",
    shippingAddressId: 0,
    ongoingItems: [],
  }
  );

  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { refetch: refetchCartCount } =
    CallAPI({
      name: 'getCartCount',
      url: '/Cart/get-cart',
      baseURL: `${process.env.REACT_APP_DOMAIN}`,

      refetchOnWindowFocus: true,
      enabled: true,
      method: 'get',
      select: (data) => data?.data?.data,
      onSuccess: (res) => {
        setCartCount(res?.itemCount);
      },
    });


  const addToCart = CallMutation({
    url: 'Cart/add-to-cart',
    method: 'put',
    onSuccess: (res) => {
      if (res.data.success) {
        enqueueSnackbar(translate('added_successfully'));
        setCartCount(res?.data?.data?.itemCount);
      } else {
        enqueueSnackbar(res.data.message, { variant: 'error' });
      }
      setLoading(false);
    },
    onError: (res) => {
      if (res?.response?.data?.code === 522) {
        navigate(PATH_DASHBOARD.ProductNotFound, { replace: true });
      } else {
        enqueueSnackbar(res?.response?.data?.message, { variant: 'error' });
      }
      setLoading(false);
    },
  });

  const memoizedValue = useMemo(
    () => ({
      cart, setCart, addToCart, cartCount, setCartCount, refetchCartCount, loading, setLoading
    }),
    [cart, setCart, addToCart, cartCount, setCartCount, refetchCartCount, loading, setLoading]
  );
  return (
    <CartContext.Provider value={memoizedValue}>
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CartProvider;
