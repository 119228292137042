import { List, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useLocales } from '../../../../locales';
import { NavSectionProps } from '../../types';
import { StyledSubheader } from '../styles';
import GuestNavList from './GuestNavList';

export default function GuestNavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => (
        <List key={uuid()}  disablePadding sx={{ px: 2 }}>
          {group.subheader && (
            <StyledSubheader disableSticky>{`${translate(
              group.subheader
            )}`}</StyledSubheader>
          )}

          {group.items.map((list) => (
            <GuestNavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChild={!!list.children}
              isCategoryItem={list.isCategoryItem}
              isRfqItem={list.isRfqItem}
            />
          ))}
        </List>
      ))}
    </Stack>
  );
}
