import { useQuery, useMutation } from "react-query";
import { useLocales } from "src/locales";
import { axiosInstance } from "./axios";

/**
 * @name name @Required @description  Should be Unique For each Api Call
 */
/**
 * @name method  @description The method For Api Call Should be one of ('post' , 'get' , 'put','delete')
 */
/**
 * @name query  @description set Params for request
 */
/**
 * @name url @Required @description the Api EndPoint
 */
/**
 * @name baseurl  @description to override the baseurl
 */

export const CallAPI = ({
  name,
  method = "get",
  body,
  query,
  url,
  baseURL,
  headers,
  cacheTime,
  enabled = false,
  initialData,
  initialDataUpdatedAt,
  isDataEqual,
  keepPreviousData,
  meta,
  notifyOnChangeProps,
  notifyOnChangePropsExclusions,
  onError,
  onSettled,
  onSuccess,
  queryKeyHashFn,
  refetchInterval,
  refetchIntervalInBackground,
  refetchOnMount,
  refetchOnReconnect,
  refetchOnWindowFocus = false,
  retry = 0,
  retryOnMount,
  retryDelay,
  select,
  staleTime,
  structuralSharing,
  suspense,
  useErrorBoundary,
  responseType
}:any) => {
  const { currentLang } = useLocales();
 
  return useQuery(
    [name],
    () =>
      axiosInstance({
        url,
        data: body,
        method,
        headers: {
          "Accept-Language": currentLang.value,
          ...headers,
        },
        params: {...query, Lang: currentLang.value},
        baseURL,
        responseType
      }),
    {
      cacheTime,
      enabled,
      initialData,
      initialDataUpdatedAt,
      isDataEqual,
      keepPreviousData,
      meta,
      notifyOnChangeProps,
      notifyOnChangePropsExclusions,
      onError,
      onSettled,
      onSuccess,
      queryKeyHashFn,
      refetchInterval,
      refetchIntervalInBackground,
      refetchOnMount,
      refetchOnReconnect,
      refetchOnWindowFocus,
      retry,
      retryOnMount,
      retryDelay,
      select,
      staleTime,
      structuralSharing,
      suspense,
      useErrorBoundary,
    }
  );
};

export const CallMutation = ({
  method = "post",
  query,
  url,
  baseURL,
  headers,
  cacheTime,
  mutationKey,
  networkMode,
  onError,
  onMutate,
  onSettled,
  onSuccess,
  retry = false,
  retryDelay,
  useErrorBoundary,
  meta,
  responseType
}:any) => {
  const { currentLang } = useLocales();
  return useMutation({
    // @ts-ignore: Unreachable props error
    mutationFn: (body:any) =>
      axiosInstance({
        url,
        data: body,
        method,
        headers: {
            // @ts-ignore: Unreachable props error
          "Accept-Language": currentLang.value === "ar" ? "ar-SA" : "en-US",
          ...headers,
        },
        params: query,
        baseURL,
        responseType
      }),
    cacheTime,
    mutationKey,
    networkMode,
    onError,
    onMutate,
    onSettled,
    onSuccess,
    retry,
    retryDelay,
    useErrorBoundary,
    meta,
  });
};
