import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useOffSetTop from 'src/hooks/useOffSetTop';
import { HEADER } from 'src/config-global';
import banner from '../../../assets/gust/BannerEn.png';
import bannerAR from '../../../assets/gust/BannerAr.png';
import { StyledRoot, StyledSectionBg, StyledSection } from './style';

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
  showHeader?: boolean;
};

export default function LoginLayout({ children, illustration, title, showHeader = false }: Props) {
  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
      <StyledRoot>
        <StyledSection>
          <Box
            src={theme.direction === 'rtl' ? bannerAR : banner}
            alt="Logo"
            component="img"
          />
        </StyledSection>

        {children}
      </StyledRoot>
  );
}
