import React, { useMemo, useState, useContext, useEffect } from 'react';
import { CallAPI } from 'src/utils/API/APIConfig';
import { CATEGORIES_LOOK_UP } from 'src/utils/API/EndPoints/HomeEp';
import PropTypes from 'prop-types';
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';

import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ItemType } from 'src/utils/Enums';

export const CategoriesContext = React.createContext();

const CategoriesProvider = ({ children }) => {
  const { currentLang } = useLocales();
  const { isAuthenticated } = useContext(UserContext);

  const [Categories, setCategories] = useState([]);
  const [Stores, setStores] = useState([]);
  const [CategoriesPath, setCategoriesPath] = useState([]);

  const { refetch, data: categoriesData } = CallAPI({
    name: 'categories',
    url: CATEGORIES_LOOK_UP,
    refetchOnWindowFocus: false,
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    enabled: true,
    query: {
      Lang: currentLang.value,
      WithSections: true
    },
    select: (data) => data?.data?.data,
  });

  CallAPI({
    name: 'stores',
    url: CATEGORIES_LOOK_UP,
    refetchOnWindowFocus: false,
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    enabled: true,
    query: {
      Lang: currentLang.value,
      isStore: true
    },
    select: (data) => data?.data?.data,
    onSuccess: (data) => setStores(data)
  });

  useEffect(() => {
    const paths = Categories?.map((cat) => {
      const path = cat?.itemType === ItemType.Category ? `${PATH_DASHBOARD.categories.root}/${cat?.id}` : `${PATH_DASHBOARD.categories.sections}/${cat?.id}`;
      return { id: cat.id, title: cat.name, imageUrl: cat.imageUrl, path };
    });

    setCategoriesPath(paths);
  }, [Categories]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated() && refetch();
  }, [currentLang, refetch, isAuthenticated]);

  useEffect(() => {
    setCategories(categoriesData);
  }, [categoriesData, currentLang]);
  const memoizedValue = useMemo(
    () => ({
      Categories,
      Stores,
      CategoriesPath,
    }),
    [Categories, CategoriesPath, Stores]
  );
  return <CategoriesContext.Provider value={memoizedValue}>{children}</CategoriesContext.Provider>;
};

CategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CategoriesProvider;
