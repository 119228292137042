import { SVGProps } from "react"

const FinancialMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#ADBFC4"
        d="M.336 11.334a10.667 10.667 0 1 0 21.333 0 10.667 10.667 0 0 0-21.333 0Z"
      />
      <g
        stroke="#003648"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={1.6}
      >
        <path d="M11.334 5.667V17M7.97 13.71C7.97 15 9.335 16 11 16c1.668 0 3-1 3-2.29a2.02 2.02 0 0 0-.77-1.536 2.73 2.73 0 0 0-1.332-.58l-1.564-.26A3.233 3.233 0 0 1 9.251 11a2.216 2.216 0 0 1-1.29-1.9c0-1.276 1.333-2.313 3-2.313 1.367 0 2.52.69 2.887 1.647" />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default FinancialMenuIcon
