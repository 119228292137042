import { useEffect } from 'react';

declare global {
  interface Window {
    fcWidgetMessengerConfig: {
      (...args: any[]): void;
      q?: any[];
    };
    fcWidget: any;
    fcSettings: any;
  }
}
export default function FreshChat() {
  useEffect(() => {
    (function loadFreshchatWidget(d, w) {
      w.fcWidgetMessengerConfig =
        w.fcWidgetMessengerConfig ||
        function freshWidget(...args: any[]) {
          (w.fcWidgetMessengerConfig.q = w.fcWidgetMessengerConfig.q || []).push(...args);
        };
      const s = d.createElement('script');
      s.async = true;
      s.src = '//fw-cdn.com/10821054/3600219.js';
      d.head.appendChild(s);
      window.fcWidgetMessengerConfig('init', {
        token:
          'eyJraWQiOiJjdXN0b20tb2F1dGgta2V5aWQiLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJmcmVzaGNoYXQiLCJhdWQiOiJmcmVzaGNoYXQiLCJpYXQiOjE3MjU0NTQxMDIsInNjb3BlIjoiYWdlbnQ6cmVhZCBhZ2VudDpjcmVhdGUgYWdlbnQ6dXBkYXRlIGFnZW50OmRlbGV0ZSBjb252ZXJzYXRpb246Y3JlYXRlIGNvbnZlcnNhdGlvbjpyZWFkIGNvbnZlcnNhdGlvbjp1cGRhdGUgbWVzc2FnZTpjcmVhdGUgbWVzc2FnZTpnZXQgYmlsbGluZzp1cGRhdGUgcmVwb3J0czpmZXRjaCByZXBvcnRzOmV4dHJhY3QgcmVwb3J0czpyZWFkIHJlcG9ydHM6ZXh0cmFjdDpyZWFkIGFjY291bnQ6cmVhZCBkYXNoYm9hcmQ6cmVhZCB1c2VyOnJlYWQgdXNlcjpjcmVhdGUgdXNlcjp1cGRhdGUgdXNlcjpkZWxldGUgb3V0Ym91bmRtZXNzYWdlOnNlbmQgb3V0Ym91bmRtZXNzYWdlOmdldCBtZXNzYWdpbmctY2hhbm5lbHM6bWVzc2FnZTpzZW5kIG1lc3NhZ2luZy1jaGFubmVsczptZXNzYWdlOmdldCBtZXNzYWdpbmctY2hhbm5lbHM6dGVtcGxhdGU6Y3JlYXRlIG1lc3NhZ2luZy1jaGFubmVsczp0ZW1wbGF0ZTpnZXQgZmlsdGVyaW5ib3g6cmVhZCBmaWx0ZXJpbmJveDpjb3VudDpyZWFkIHJvbGU6cmVhZCBpbWFnZTp1cGxvYWQiLCJ0eXAiOiJCZWFyZXIiLCJjbGllbnRJZCI6ImZjLTJmMmIyY2EzLTQ4NzEtNGEzNS1hNDkyLThiMjY2NzM3NmZiYiIsInN1YiI6IjdjMjU4MWQ0LTJhNjEtNGI5NC05NzI0LTQ5MGZmY2RhMTg3NyIsImp0aSI6IjdiZjE3MzQ4LTRlM2MtNDI0ZC04MGQ3LWE0MjM5NTdiOGUwMCIsImV4cCI6MjA0MDk4NjkwMn0.Ue8xznzcVf-nA-uGgXRvKy3nDbXteIOQvPMMbAWboGw',
        host: 'lawazem-team-8994c2896472ada16941052.freshchat.com/v2',
        widgetId: 'cb8ef1f9-5472-4b17-a3ad-4d8c3e44cb6f',
        chat: 'true',
      });
    })(document, window);
  }, []);

  return null;
}
