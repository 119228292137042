import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Badge,
  BadgeProps,
  Typography,
  alpha,
  Link,
} from '@mui/material';
import ic_cart from 'src/assets/icons/ic_cart.png';
import SideMenu from 'src/assets/icons/SideMenu';
import avatarSrc from 'src/assets/shared/avatar.png';
import { useLocales } from 'src/locales';
import GuestPopup from 'src/pages/auth/login/GuestLogin';
import { IconButtonAnimate } from 'src/components/animate';
import BillIcon from 'src/assets/icons/Bill';
import { useSettingsContext } from '../../../components/settings';
import LanguagePopover from './LanguagePopover';
import Iconify from '../../../components/iconify';
import Logo from '../../../components/logo';
import { HEADER, NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import useOffSetTop from '../../../hooks/useOffSetTop';
import { bgBlur } from '../../../utils/cssStyles';
import GuestSearchBar from '../../../sections/header/search/GuestSearchInput';
import DownlodeApp from './DownlodeApp';

type Props = {
  shouldShowContent?: boolean;

  onOpenNav?: VoidFunction;
  showHomeContent?: boolean;
};

export default function GuestHeader({ onOpenNav, shouldShowContent, showHomeContent }: Props) {
  const [openGuestPopup, setOpenGuestPopup] = useState(false);
  const { currentLang, translate } = useLocales();
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const bodyWidth =
    document?.getElementsByTagName('body')[0]?.getBoundingClientRect()?.width || '100%';
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    marginInlineEnd: '6px',
    '& .MuiBadge-badge': {},
  }));

  const handleOpenGuestPopup = async () => {
    setOpenGuestPopup(true);
  };

  const StyledRoot = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
    cursor: 'pointer',
  }));

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {isDesktop && (
        <div style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack sx={{ width: '100%', maxWidth: '815px', mr: '10px' }}>
              <GuestSearchBar />
            </Stack>
            <Stack
              flexShrink={0}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <LanguagePopover />
              <a onClick={handleOpenGuestPopup} href="#">
                <StyledBadge badgeContent="0" showZero color="primary">
                  <Box component="img" src={ic_cart} sx={{ width: 35, height: 35 }} />
                </StyledBadge>
              </a>
              <Link onClick={handleOpenGuestPopup} href="#">
                <StyledRoot>
                  <Box
                    component="img"
                    src={avatarSrc}
                    sx={{
                      borderRadius: '50%',
                      width: 30,
                      height: 30,
                      marginInlineEnd: 1,
                      ml: '2px',
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'text.secondary', textDecoration: 'none' }}
                  >
                    {` ${translate('auth.login')}`}
                  </Typography>
                </StyledRoot>
              </Link>
            </Stack>
          </Box>
          {openGuestPopup && (
            <GuestPopup open={openGuestPopup} onClose={() => setOpenGuestPopup(false)} />
          )}
        </div>
      )}

      {!isDesktop && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <DownlodeApp />
          {shouldShowContent && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '80%',
                flexDirection: 'row-reverse',
                width: '100%',
                alignItems: 'center',
                px: { xs: 2, sm: 3 },
              }}
            >
              {showHomeContent && (
                <Stack
                  spacing={{ xs: 0.5, sm: 1 }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'flex-start',
                    alignItems: 'start',
                    justifyContent: 'start',
                    pl: '16px',
                  }}
                >
                  <IconButtonAnimate
                    color="primary"
                    onClick={handleOpenGuestPopup}
                    sx={{ width: '30px', height: '30px', p: 0, alignItems: 'end' }}
                  >
                    <BillIcon />
                  </IconButtonAnimate>
                </Stack>
              )}

              <Stack sx={{ width: '100%' }}>
                <GuestSearchBar />
              </Stack>
              {showHomeContent && (
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pr: '16px',
                  }}
                >
                  <IconButton onClick={onOpenNav} sx={{ color: 'text.primary', padding: '0' }}>
                    <SideMenu />
                  </IconButton>
                </Stack>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: shouldShowContent ? HEADER.H_MOBILE : 72,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          paddingLeft: `calc(((100% - ${bodyWidth}px ) / 2 ) + ${NAV.W_DASHBOARD - 1}px)`,
          paddingRight: `calc((100% - ${bodyWidth}px ) / 2 )`,
          backgroundColor: '#F6F7F8',
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          backgroundColor: 'white',
          ...(!isDesktop && {
            px: '0px !important',
          }),
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
