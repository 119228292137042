import { Button, Stack, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import MobileSvg from 'src/assets/illustrations/MobileView';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/assets/illustrations/Logo';
import { PATH_DASHBOARD } from 'src/routes/paths';

const MobileView = () => {
  const { translate } = useLocales();
  const IS_APP_STORE = 'appStore';

  const renderBtnContent = (type?: string) => (
    <Stack direction="column">
      <Typography
        variant="subtitle2"
        sx={{
          color: '#003648',
          fontSize: '14px',
          marginBottom: 0,
          fontFamily: 'SomarSans',
          fontWeight: 400,
        }}
      >
        Available on the
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          color: '#4545DB',
          fontSize: '16px',
          marginBottom: 0,
          fontWeight: 700,
          fontFamily: 'SomarSans',
        }}
      >
        {type === 'appStore' ? 'App Store' : 'Google Play'}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction="column" alignItems="center">
      <Stack mt="20px" mb="50px" display="flex" flexDirection="column-reverse" gap="70px">
        <MobileSvg />
        <Logo />
      </Stack>
      <Stack direction="column" alignItems="center">
        <Typography
          sx={{
            marginBottom: '24px',
            color: '#003648',
            fontSize: '20px',
            fontWeight: '700',
            textAlign: 'center',
          }}
          variant="h5"
        >{`${translate('mobile_redirect_message')}`}</Typography>
      </Stack>
      <Button
          component={RouterLink}
          to={PATH_DASHBOARD.MarketPlace.root}
          size="large"
          variant="contained"
        >
         {`${translate('Go_to_Home')}`}
        </Button>
    </Stack>
  );
};

export default MobileView;
