import React, { useMemo, useState, useContext, useEffect } from 'react';
import { CallAPI } from 'src/utils/API/APIConfig';
import { Anonymous_CATEGORIES_LOOK_UP } from 'src/utils/API/EndPoints/HomeEp';
import PropTypes from 'prop-types';
import { useLocales } from 'src/locales';
import { ANONYMOUS_PATH_DASHBOARD } from 'src/routes/paths';
import { ItemType } from 'src/utils/Enums';

export const GuestCategoriesContext = React.createContext();

const GuestCategoriesProvider = ({ children }) => {
  const { currentLang } = useLocales();
  // const { isAuthenticated } = useContext(UserContext);

  const [Categories, setCategories] = useState();
  const [Stores, setStores] = useState([]);
  const [CategoriesPath, setCategoriesPath] = useState([]);

  CallAPI({
    name: ['categories', currentLang],
    url: Anonymous_CATEGORIES_LOOK_UP,
    refetchOnWindowFocus: false,
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    enabled: true,
    query: {
      Lang: currentLang.value,
      WithSections: true
    },
    select: (data) => data?.data?.data,
    onSuccess: (res) => {
      setCategories(res);
      const paths = res?.map((cat) => {
        const path = cat?.itemType === ItemType.Category ? `${ANONYMOUS_PATH_DASHBOARD.categories.root}/${cat?.id}` : `${ANONYMOUS_PATH_DASHBOARD.categories.sections}/${cat?.id}`;
        return { id: cat.id, title: cat.name, imageUrl: cat.imageUrl, path };
      });
      setCategoriesPath(paths);
    },
  });

  CallAPI({
    name: 'stores',
    url: Anonymous_CATEGORIES_LOOK_UP,
    refetchOnWindowFocus: false,
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    enabled: true,
    query: {
      Lang: currentLang.value,
      isStore: true
    },
    select: (data) => data?.data?.data,
    onSuccess: (data) => setStores(data)
  });
  const memoizedValue = useMemo(
    () => ({
      Categories,
      CategoriesPath,
      Stores,
    }),
    [Categories, CategoriesPath, Stores]
  );
  return <GuestCategoriesContext.Provider value={memoizedValue}>{children}</GuestCategoriesContext.Provider>;
};

GuestCategoriesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GuestCategoriesProvider;
