import { useEffect, useState, useContext } from 'react';
import { CallAPI, CallMutation } from 'src/utils/API/APIConfig';
import {
  Box,
  Typography,
  Button,
  ListItemButton,
  ListItemText,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import { UserContext } from 'src/utils/Contexts/UserContext/UserProvider/UserProvider';
import { CartContext } from 'src/utils/Contexts/Cart/CartProvider';

import MenuPopover from 'src/components/menu-popover';
import buildingIcon from 'src/assets/icons/building.png';
import ConfirmDialog from 'src/components/confirm-dialog';
import AddCompany from 'src/sections/shared/AddCompany';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { useNavigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { RightIcon } from '../../../components/nav-section/vertical/Icon';

const Companies = ({ translate }) => {
  const { UserInfo, setLogin, setCurrentCumpanyInfo } = useContext(UserContext);
  const { refetchCartCount } = useContext(CartContext);

  const [customized, setCustomized] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [comapnyLabel, setCompanyLabel] = useState(UserInfo.companyName);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCompanyLabel(UserInfo.companyName);
  }, [UserInfo.companyName]);

  const {
    data: companies,
    refetch: callCompanies,
    isFetching: companiesLoading,
  } = CallAPI({
    name: 'companies',
    url: '/company/get-company-list-user',
    baseURL: process.env.REACT_APP_AUTH,
    enabled: true,
    select: (res) => res.data.data,
  });
  const {
    data: currentCompany,
    isFetching: companyLoading,
    refetch: getCurrentCompany,
  } = CallAPI({
    name: 'getCurrentCompany',
    url: '/company/get-current-company',
    enabled: true,
    select: (res) => res.data.data,
    refetchInterval: 1000 * 60 * 60,
    onSuccess: (data) => {
      setCurrentCumpanyInfo(data);
    },
  });

  const handleOpenCustomized = (event) => {
    setCustomized(event.currentTarget);
  };
  const handleCloseCustomized = () => {
    setCustomized(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const switchCompany = CallMutation({
    url: 'Users/generate-token-v2',
    baseURL: process.env.REACT_APP_AUTH,
    onSuccess: ({ data }) => {
      if (data.success) {
        setLogin({
          accessToken: data.data?.accessToken,
          refreshToken: data.data?.refreshToken,
        });
        setCompanyLabel(selectedCompany.name);
        refetchCartCount();
        getCurrentCompany();
        if (window.location.pathname === '/marketplace') {
          window.location.reload(false);
        } else {
          navigate(`${PATH_AFTER_LOGIN}`);
        }
      }
    },
  });

  const handleSwitchCompany = () => {
    switchCompany.mutate({
      phoneNumber: UserInfo.phoneNumber,
      companyId: selectedCompany?.id,
      otp: null,
      deviceId: localStorage.getItem('deviceId'),
    });
  };

  const handleOpenAddCompany = () => {
    setOpenAddCompany(true);
    setSelectedCompany(null);
  };
  const handleCloseAddCompany = () => {
    setOpenAddCompany(false);
  };

  return (
    <>
      {companies?.length <= 1 ? (
        <Typography href="#" variant="body2" sx={{ color: 'greycolor.main', padding: '0' }}>
          {comapnyLabel}
        </Typography>
      ) : (
        <>
          <ListItemButton
            href="#"
            variant="body2"
            sx={{
              color: 'greycolor.main',
              padding: '0',
              '& .MuiTypography-root': { fontSize: '14px' },
            }}
            onClick={(e) => handleOpenCustomized(e)}
          >
            <ListItemText
              p="0"
              primary={comapnyLabel}
              sx={{ overflowWrap: 'break-word', flexShrink: 3 }}
            />
            {companies?.length > 1 && <RightIcon icon="eva:arrow-ios-downward-fill" />}
          </ListItemButton>

          {customized && (
            <MenuPopover open={customized} onClose={handleCloseCustomized} arrow="top-center">
              <Scrollbar sx={{ maxHeight: '300px' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {companies?.map(
                    (item) =>
                      item.id !== currentCompany?.id && (
                        <ListItemButton
                          key={item.id}
                          onClick={() => {
                            setSelectedCompany(item);
                            setOpenConfirm(true);
                          }}
                        >
                          <ListItem alignItems="center">
                            <ListItemAvatar>
                              <Box
                                component="img"
                                src={buildingIcon}
                                sx={{ width: 40, height: 40 }}
                              />
                            </ListItemAvatar>
                            <ListItemText>
                              <Typography>{item?.name}</Typography>
                            </ListItemText>
                          </ListItem>
                        </ListItemButton>
                      )
                  )}
                </List>
              </Scrollbar>
              <Stack variant="contained" minWidth="250px" color="Background">
                <Button
                  sx={{ borderRadius: '5px' }}
                  fullWidth
                  size="med"
                  onClick={handleOpenAddCompany}
                  padding="0"
                  variant="contained"
                >
                  {`${translate('Add_new_company')}`}
                </Button>
              </Stack>
            </MenuPopover>
          )}
          {openAddCompany && (
            <AddCompany
              open={openAddCompany}
              handleClose={handleCloseAddCompany}
              company={selectedCompany}
              callCompanies={callCompanies}
            />
          )}
          <ConfirmDialog
            sx={{
              '& .MuiDialogContent-root': {
                overflowY: 'initial',
              },
            }}
            open={openConfirm}
            onClose={handleCloseConfirm}
            title={`${translate('change_company')}`}
            content={
              <span>{`${translate('message_confirm_switch_company')} "${selectedCompany?.name
                }"${translate('question_mark')}`}</span>
            }
            action={
              <Button
                variant="contained"
                onClick={() => {
                  handleSwitchCompany();
                  handleCloseConfirm();
                  setCustomized(null);
                }}
              >
                {`${translate('change_company')}`}
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

export default Companies;
