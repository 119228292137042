
type InputValue = string | null | undefined;

export function replaceArabicWithEnglishNumerals(number: InputValue) {
  if (number == null) return '';

  const arabicToEnglishMap = {
    '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
    '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
  };
  return number?.split('').map(char => arabicToEnglishMap[char as keyof typeof arabicToEnglishMap] || char).join('');
}
